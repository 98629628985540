import React from 'react';
import {InfoBanner, Spinner, TableHeader} from '@services/ui-components';
import {Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {currentCompanyIdVar} from '@me-team/host/src/apollo/globalVar/state';
import CreateProductForm from './CreateProductForm';

const CreateProduct:React.FC = () => {
  const { t } = useTranslation();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);

  if (!currentCompanyId) return <Spinner/>;

  return (
    <div className='mb-3'>
      <InfoBanner>
        <p className='fs-7 mb-0'>{t('')}</p>
      </InfoBanner>
      <TableHeader
        title={t('Add New Product')}
        paths={[
          {path: `/warehouse/${currentCompanyId}/new-product`, label: t('Warehouse accounting')},
          {path: `/warehouse/product/${currentCompanyId}`, label: t('Products')},
          {path: `/warehouse/${currentCompanyId}/new-product`, label: t('Add New Product')},
        ]}
        colSize={12}
      />
      <Row>
        <CreateProductForm />
      </Row>
    </div>
  );
};

export default CreateProduct;
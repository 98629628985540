import React from 'react';
import {Field, ErrorMessage, useField} from 'formik';
import {Button, Col, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';

interface TextInputWithButtonProps {
  id: string;
  name: string;
  index: number;
  remove: (index: number) => void;
  label: string;
}

const TextInputWithButton: React.FC<TextInputWithButtonProps> = ({
                                                                   id,
                                                                   name,
                                                                   index,
                                                                   remove,
                                                                   label
                                                                 }) => {
  const {t} = useTranslation();
  const [_, meta] = useField(name);

  return (
    <Row>
      <Col md={2}>
        <label
          htmlFor={id}
          className='text-dark fs-7 fw-bold'>
          {label}<>&nbsp;<span className='text-danger'>*</span></>
        </label>
      </Col>
      <Col md={10}>
        <div className='d-flex align-items-center w-100 gap-2'>
          <Field
            id={id}
            name={name}
            placeholder={t('Add property')}
            className={`form-control fs-7 ${meta.touched && meta.error ? 'is-invalid' : ''}`}
            maxLength={50}
          />
          <Button
            variant='outline-secondary'
            className='btn-square'
            style={{width: '49px', height: '49px'}}
            onClick={() => remove(index)}
          >
            <i className="bi bi-dash text-dark"></i>
          </Button>
        </div>
        <ErrorMessage name={name}>
          {msg => <div className='invalid-feedback d-block'>{msg}</div>}
        </ErrorMessage>
      </Col>
    </Row>
  );
};

export default TextInputWithButton;

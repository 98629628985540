import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Col,Button } from "react-bootstrap";
import { TableHeader} from '@services/ui-components';
import VioletBadge from '@services/ui-components/src/VioletBadge/VioletBadge';
import WarehousesTable from '../Warehouse/WarehousesTable/WarehousesTable';
import {useReactiveVar} from "@apollo/client";
import {currentBranchIdVar} from "@me-team/host/src/apollo/globalVar/state";

const Warehouse: React.FC = () => {
    const { t } = useTranslation()
    const currentBranchId = useReactiveVar(currentBranchIdVar);
    const [showPositionModal, setShowPositionModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const handleAddWarehouseModal = () => setShowPositionModal(prevState => !prevState)

    return (
        <>
            <VioletBadge
                text={t('Lorem')}
            />
            <TableHeader
                title={t('Warehouses')}
                paths={[
                    { path: "/warehouses", label: t('Warehouse accounting') },

                    { path: "/warehouses", label: t('Warehouses') },
                ]}
                buttons={
                    <Col className="text-end">
                            <Button variant="primary" className="fs-7 fw-bold btn-primary custom-btn"
                                    onClick={handleAddWarehouseModal}
                            >
                                <i className="bi bi-plus-lg me-1"></i>
                                {t('Add Warehouse')}
                            </Button>
                    </Col>
                }
                showFilters={false}
            >
            </TableHeader >

            <WarehousesTable show={showPositionModal} handleModal={handleAddWarehouseModal} branchId={currentBranchId} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
        </>
    );
};

export default Warehouse;
import {StorageProxy} from "@me-team/host/graphql/types";
import * as Yup from "yup";

interface Option {
    value: string | number;
    label: string;
}
export interface WearhousesModalProps {
    show: boolean;
    handleModal: () => void;
    branchId?: number;
    refetch: () => void;
    toggleShowToast?: (text: string) => void;
    warehouse?: StorageProxy
    storages?: Option[]
}

export const warehouseValidationSchema = (t: (key: string) => string) =>
    Yup.object().shape({
        name: Yup.string()
            .trim()
            .max(50, t('The maximum length of the Job Title field is 50 characters'))
            .required(t('Fill this field')),
        storageType: Yup.number().required(t('Fill this field')),
        description: Yup.string().max(400, t('The maximum length of the Description field is 400 characters'))
    });
import React, {useState} from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {InfoBanner, TableHeader} from '@services/ui-components';
import {useReactiveVar} from '@apollo/client';
import {currentCompanyIdVar, currentUserRoleVar} from '@me-team/host/src/apollo/globalVar/state';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import ProductsTable from './ProductsTable';
import ProductsFilterWrapper from './ProductsFilterWrapper';
import {ProductsFilterI} from '../types';
import {UserRole} from '../../../utils/enums';
import {useNavigate} from 'react-router-dom';

const ProductsList: React.FC = () => {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const userRole = useReactiveVar(currentUserRoleVar);
  const isEmployeeRole = +userRole === UserRole.EMPLOYEE;
  const [showFilters, setShowFilters] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);

  const initialValueFilter: ProductsFilterI = {
    nameSkuBarcode: null,
    categoryId: null,
    brand: null,
    stockStatus: null,
  }

  const [filters, setFilters] = useState<ProductsFilterI>(initialValueFilter);

  const toggleFilters = () => setShowFilters(!showFilters);
  const handleCreateProduct = () => {
    navigate(`/warehouse/${currentCompanyId}/new-product`);
  };

  return (
    <div className='mb-9 px-2 px-lg-0'>
      <InfoBanner>
        <p className='fs-7 mb-0'>{t('')}</p>
      </InfoBanner>
      <TableHeader
        title={t('List of Products')}
        paths={[
          {path: `/warehouse/product/${currentCompanyId}`, label: t('Warehouse accounting')},
          {path: `/warehouse/product/${currentCompanyId}`, label: t('Products')},
        ]}

        colSize={9}
        btnColSize={3}
        buttons={
          <Row
            className='gx-1 w-100  d-flex flex-lg-nowrap justify-content-lg-end gap-3'>
            {
              !isEmployeeRole
                ?
                <Col
                  xs={12}
                  md={6}
                  lg={7}
                >
                  <Button
                    variant='primary'
                    className='w-100 text-white mb-3 mb-lg-0 '
                    onClick={handleCreateProduct}
                  >
                    <i className='bi bi-plus-lg fw-bold me-1 w-100 h-100'></i>
                    {t('Add Product')}
                  </Button>
                </Col>
                : null
            }
            <Col
              xs={12}
              md={6}
              lg={5}
              className='px-0'
            >
              <Button
                variant='additional-violet'
                className=' fw-normal border-primary text-primary w-100 mb-3 mb-lg-0'
                onClick={toggleFilters}
              >
                <i className='bi bi-funnel me-1'></i>
                {t('filters')}
              </Button>
            </Col>
          </Row>
        }
        showFilters={showFilters}
      >
        <ProductsFilterWrapper setFilters={setFilters}/>
      </TableHeader>
      <ProductsTable filters={filters}/>

      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </div>
  );
};

export default ProductsList;
import React, { memo } from 'react';

const Spinner: React.FC = memo(() => {
    return (
        <div className='d-flex align-items-center justify-content-center bg-white'>
            <div className='preloader__spinner'></div>
        </div>
    );
});

export default Spinner;

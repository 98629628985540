import React, {useEffect, useState} from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row, Navbar, Offcanvas, CloseButton } from "react-bootstrap";
import LanguageDropdown from "../LanguageDropdown/LanguageDropdown";
import { useGetBranchesQuery } from '../../modules/branches/graphql/branches.hooks';
import { useReactiveVar } from '@apollo/client';
import { currentBranchIdVar, userIdVar, currentCompanyIdVar, currentUserRoleVar} from '../../apollo/globalVar/state';
import { useUpdateUserMutation } from '../../apollo/globalVar/graphql/globalVar.hooks';
import AccessService from "../../../services/AccessService";
import { useGetEmployeesQuery } from "../../modules/employeeList/graphql/employeeList.hooks";
import { useGetCompanyModulesQuery } from "../../modules/company/graphql/company.hooks";
import SidebarHeader from "../Sidebar/SidebarHeader";
import SidebarSubheader from "../Sidebar/SidebarSubheader";
import SidebarBranchList from "../Sidebar/SidebarBranchList";
import SidebarMenu from "../Sidebar/SidebarMenu";
import { branchesLength, isRefetchBranches } from "../../modules/branches/state";
import { refetchUserDataMenu } from "../../apollo/apollo-client";
import { ModuleProxy } from "../../../graphql/types";


export const App = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const [updateUser] = useUpdateUserMutation();
    const basePath = process.env.REACT_APP_POS_URL;
    const [isShowNavbar, setShowNavbar] = useState<boolean>(false);

    const isRefetch = useReactiveVar(isRefetchBranches);
    const accessService = AccessService.getInstance().isPremium;
    const userRole = useReactiveVar(currentUserRoleVar);

    const { data: userData, loading: loadingUserData } = useGetEmployeesQuery({
        variables: { page: 1, itemsPerPage: 8 },
        context: {
            errorType: 'global',
        },
    });
    const company = userData?.user?.company
    const role = userData?.user?.role?.id
    if (company && role) {
        AccessService.getInstance().init(company?.paidFeatures)
        AccessService.getInstance().setUserRole(role);
    }
    const { data, loading, error, refetch } = useGetBranchesQuery({
        variables: {
            itemsPerPage: 30,
            page: 1,
            branchTypeId: null,
            name: null,
        },
        context: {
            errorType: 'global',
        },
        onCompleted: (data) => {
            const filteredBranches = data?.user?.company?.branchesPaginated?.branches?.filter(branch => !branch.deleted);
            branchesLength(filteredBranches?.length);
        },
    });

    const { data: modules } = useGetCompanyModulesQuery({
        context: {
            errorType: 'global',
            },
        }
    );
    const hasWarehouseModule = modules?.user?.company?.modules?.some((module: ModuleProxy) => module.name === "Warehouses");

    useEffect(() => {
        refetch();
        refetchUserDataMenu();
        isRefetchBranches(false);
    }, [isRefetch]);


    const branches = data?.user?.company?.branchesPaginated?.branches || [];
    const activeBranches = branches?.filter(branch => !branch.deleted) || [];
    const companyMain = data?.user?.company;
    const userId = useReactiveVar(userIdVar);
    const currentBranchId = useReactiveVar(currentBranchIdVar);
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);


    const accordionsData = [
        ...(
            hasWarehouseModule
                ? [{
                    title: t("Warehouse accounting"),
                    items: [
                        { label: t('Warehouses'), to: `/warehouses` },
                        {
                            label: t('Products'),
                            to: `warehouse/product/${currentCompanyId}`
                        },
                        userRole !== 5 && { label: t('Add product'), to: `/warehouse/${currentCompanyId}/new-product` },
                        {
                            label: t('Technological maps'),
                            to: `${basePath}/warehouse/technologicalMap/${currentCompanyId}`
                        },
                        { label: t('Reports'), to: `${basePath}/warehouse/reports/branch/${currentBranchId}` },
                        { label: t('Product categories'), to: `${basePath}/warehouse/goods/${currentCompanyId}/category` },
                        {
                            label: t('Technological map categories'),
                            to: `${basePath}/warehouse/technologicalMapCategory/${currentCompanyId}` }
                    ],
                    icon: "bi bi-shop-window"
                }]
                : []
        ),
        {
            title: t("Personnel management"),
            items: [
                { label: t('Employees'), to: `employees-list` },
                userRole !== 5 && {
                    label: t('Add an employee'),
                    to: `employees-list/add-employee`
                },
                { label: t('Positions'), to: `positions` },
                {
                    label: t('Work schedule'),
                    to: `${basePath}/personnel/schedule/${currentCompanyId}/${currentBranchId}/employee`
                },
                { label: t('Reviews'), to: `${basePath}/booking/review/company/${currentCompanyId}` }
            ],
            icon: "bi-layout-text-window-reverse"
        },
        {
            title: t('Services'),
            items: [
                { label: t('Service list'), to: `${basePath}/services/service/${currentCompanyId}/service-list` },
                userRole !== 5 && { label: t('Add a service'), to: `${basePath}/services/service/${currentCompanyId}/new` },
                { label: t('Service Categories'), to: 'service-categories' },
            ],
            icon: "bi-clipboard"
        },
        {
            title: t('Logbook'),
            items: [
                { label: t('Logbook'), to: `${basePath}/journal/booking/${currentBranchId}` },
                { label: t('Add record'), to: `${basePath}/journal/booking/new/${currentBranchId}` },
                { label: t('Booking Statistic'), to: `${basePath}/booking/statistic/branch/${currentBranchId}` }
            ],
            icon: "bi-journals"
        },
        {
            title: t('Clients'),
            items: [
                { label: t('Client list'), to: `${basePath}/clients/${currentCompanyId}/list` },
                userRole !== 5 && { label: t('Add client'), to: `${basePath}/clients/${currentCompanyId}/new` },
                userRole !== 5 && { label: t('History of visits'), to: 'clients-history-visit' }
            ],
            icon: "bi-people"
        },
        {
            title: t('Online Booking'),
            items: [
                { label: t('Online Booking Config'), to: `${basePath}/booking/company/${currentCompanyId}/config` }
            ],
            icon: "bi-calendar4-event"
        },
        ...(
            userRole !== 5
                ? [{
                    title: t('Management of Tariffs'),
                    items: [
                        { label: t('Tariff Plans'), to: `${basePath}/payment/company/${currentCompanyId}/tariff` },
                        { label: t('Modules'), to: `${basePath}/payment/module/company/${currentCompanyId}` },
                        { label: t('Payment History'), to: `${basePath}/payment/history/company/${currentCompanyId}` },
                    ],
                    icon: "bi-card-checklist"
                }]
                : []
        ),
    ];

    const handleChangeBranch = async (branchId: number) => {
        try {
            await updateUser({
                variables: {
                    companyId: +currentCompanyId,
                    userId: +userId,
                    input: {
                        currentBranch: branchId
                    }
                }
            });
            currentBranchIdVar(branchId);
            window.location.href = `${basePath}/branch/company/${currentCompanyId}/${branchId}`;
        } catch (e) {
            console.error('Error changing branch:', e);
        }
    };

    const isRegistrationOrCreateOwner = location.pathname === "/registration" || location.pathname === "/create-owner";
    const hasAbilityToCreateBranch = userRole !== 5 && (accessService || activeBranches.length < 2);

    const handleCloseNavbar = () => setShowNavbar(false);
    const handleShowNavbar = () => setShowNavbar(true);

    if (loading || loadingUserData) return <></>

    return (
            <div className='pt-4'>
                <Row className='gx-0'>
                    { userId ? (
                        <Col xs={12} md={4} lg={3} xl={2} className='px-3 nav-menu'>
                            <div className="d-md-none">
                                <Navbar fixed="top" expand={false} className="bg-white position-fixed border-bottom">
                                    <Container fluid>
                                        {isRegistrationOrCreateOwner ? (
                                            <>
                                                <Navbar.Toggle className="border-0 px-1" onClick={handleShowNavbar}>
                                                    <i className="bi bi-list fs-3"></i>
                                                </Navbar.Toggle>
                                                <Navbar.Brand href="#" className="nav-item d-flex align-items-center flex-grow-1 justify-content-center py-0">
                                                    <SidebarHeader isMobile />
                                                </Navbar.Brand>
                                            </>
                                        ) : (
                                            <>
                                                <Navbar.Toggle className="border-0 px-1" onClick={handleShowNavbar}>
                                                    <i className="bi bi-list fs-3"></i>
                                                </Navbar.Toggle>
                                                <Navbar.Brand href="#" className="nav-item d-flex align-items-center flex-grow-1 justify-content-center py-0">
                                                    <SidebarSubheader company={companyMain} isMobile />
                                                </Navbar.Brand>
                                                <LanguageDropdown isMobile/>
                                            </>
                                        )}

                                        <Offcanvas show={isShowNavbar} onHide={handleCloseNavbar}>
                                            <Offcanvas.Header onHide className="border-bottom py-2">
                                                <CloseButton aria-label="Hide" onClick={handleCloseNavbar} />
                                                <Offcanvas.Title className="d-flex align-items-center flex-grow-1 justify-content-center">
                                                    {isRegistrationOrCreateOwner ? <SidebarHeader isMobile /> : <SidebarSubheader company={companyMain} handleCloseNavbar={handleCloseNavbar} isMobile />}
                                                </Offcanvas.Title>
                                                {!isRegistrationOrCreateOwner && <LanguageDropdown handleCloseNavbar={handleCloseNavbar} isMobile/>}
                                            </Offcanvas.Header>
                                            <Offcanvas.Body>
                                                {hasAbilityToCreateBranch && (
                                                    <div className="nav-item-menu rounded-1 bg-white py-0 mb-2">
                                                        <Link className={`nav-link-menu text-primary ${isRegistrationOrCreateOwner ? "pe-none opacity-25" : ""}`} to={'create-branch'} onClick={handleCloseNavbar}><i
                                                            className='bi bi-plus-lg me-2'></i>{t("Add branch")}</Link>
                                                    </div>
                                                )}
                                                {!isRegistrationOrCreateOwner && <SidebarBranchList branches={branches} handleCloseNavbar={handleCloseNavbar} handleChangeBranch={handleChangeBranch}/>}
                                                <SidebarMenu accordionsData={accordionsData} handleCloseNavbar={handleCloseNavbar} isRegistrationOrCreateOwner={isRegistrationOrCreateOwner}/>
                                            </Offcanvas.Body>
                                        </Offcanvas>
                                    </Container>
                                </Navbar>
                            </div>
                            <div className="d-none d-md-block menu-container">
                                <SidebarHeader />
                                {!isRegistrationOrCreateOwner && (
                                    <div className="d-flex justify-content-between mb-3 pe-3">
                                        <SidebarSubheader company={companyMain} />
                                    </div>
                                )}
                                {hasAbilityToCreateBranch && (
                                    <div className="nav-item-menu rounded-1 bg-white py-1">
                                        <Link className={`nav-link-menu text-primary ${isRegistrationOrCreateOwner ? "pe-none opacity-25" : ""}`} to={'create-branch'}><i
                                            className='bi bi-plus-lg me-2'></i>{t("Add branch")}</Link>
                                    </div>
                                )}
                                {!isRegistrationOrCreateOwner && (
                                    <SidebarBranchList branches={branches} handleChangeBranch={handleChangeBranch}/>
                                )}
                                <SidebarMenu accordionsData={accordionsData} isRegistrationOrCreateOwner={isRegistrationOrCreateOwner}/>
                            </div>
                        </Col>
                    ) : null }
                    <Col xs={12} md={8} lg={9} xl={10} className='pt-5 pt-md-0 mx-auto'>
                        <Container fluid className="px-3 px-xxl-1">
                            <Col xs={12} xl={10} className="offset-xl-1">
                                <Outlet />
                            </Col>
                        </Container>
                    </Col>
                </Row>
            </div>
    );
};


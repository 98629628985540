import React, {ReactNode} from 'react';
import { Navigate, useParams } from 'react-router-dom';
import {useReactiveVar} from '@apollo/client';
import {currentCompanyIdVar, currentUserRoleVar} from '@me-team/host/src/apollo/globalVar/state';

interface ProtectedRouteProps {
  children: ReactNode;
  restrictedRoles?: number[];
}

const ProtectedRoute:React.FC<ProtectedRouteProps> = ({
                                                        children,
                                                        restrictedRoles = []
                                                      }) => {
  const companyId = useReactiveVar(currentCompanyIdVar);
  const { currentCompanyId }  = useParams();
  const userRole = useReactiveVar(currentUserRoleVar);

  const isInvalidCompanyId = +currentCompanyId !== +companyId;
  const isRestrictedRole = restrictedRoles.includes(+userRole);

  if (isInvalidCompanyId || isRestrictedRole) {
    return <Navigate to="/error/403" replace />;
  }
  return children;
};

export default ProtectedRoute;
import { Table } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
    useGetCurrencySignQuery,
    useGetHistoryAppointmentQuery,
} from "@me-team/host/src/modules/appointment/graphql/appointment.hooks";
import { TableHeader } from '@services/ui-components';
import Spinner from "@services/ui-components/src/Spinner/Spinner";
import VioletBadge from '@services/ui-components/src/VioletBadge/VioletBadge';
import BookingHistoryDataItem from './components/BookingHistoryDataItem';
import { formatDate, getName } from './utils/utils';
import React from 'react';


const HistoryChangeAppointment: React.FC = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { data, loading } = useGetHistoryAppointmentQuery({
        variables: { id: +id  }
    })
    const { data: currency, loading: currencyLoading } = useGetCurrencySignQuery()

    const appointment = data?.user.company.branches[0].bookings.bookings[0].bookingHistories
    const currencySign = currency?.user.company.currency.code

    if (loading || currencyLoading) return <Spinner />

    return (
        <>
            <VioletBadge text={"Text"} />
            <TableHeader
                title={t('History of Changes')}
                paths={[{ path: "/change-history", label: t('Logbook') }, { path: "/change-history", label: t('Edit Record') }, { path: "/change-history", label: t('History of Changes') }]}
                showFilters={false}
            >
            </TableHeader >

            <Table responsive >
                <tbody>
                    {appointment?.map((i, index) => (
                        <tr key={i?.id}>
                            <td >
                                <div className='fs-7'>
                                    {getName(i)}
                                    {' - '}
                                    <span className="text-secondary ">
                                        {index === 0 ? t('Create Record') : (i?.exists ? t('Edit Record') : t('Change Status'))}
                                    </span>
                                    {' - '}
                                    {formatDate(i?.changeTime)}
                                </div>

                            </td>
                            <td>
                                {i?.bookingHistoryData.map((data, index) => (
                                    <BookingHistoryDataItem key={index} data={data} currency={currencySign} />
                                ))}

                            </td>
                        </tr>
                    ))}



                </tbody>
            </Table >

        </>
    );
};

export default HistoryChangeAppointment;

import React, {useEffect} from 'react';
import {ErrorMessage, Field, useField} from 'formik';
import {useTranslation} from 'react-i18next';

interface NumberInputProps {
  id: string;
  name: string;
  placeholder: string;
  disabled?: boolean;
}

const NumberInput: React.FC<NumberInputProps> = ({
                                                   id,
                                                   name,
                                                   placeholder,
                                                   disabled
                                                 }) => {
  const {t} = useTranslation();
  const [field, meta, helpers] = useField(name);

  useEffect(() => {
    if (field.value === null || field.value === undefined) {
      helpers.setValue('');
    }
  }, [field.value, helpers]);

  const isValidInteger = (value: string): boolean => {
    const regex = /^[1-9]\d*$/;
    return regex.test(value);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === '') {
      helpers.setValue('');
      return;
    }
    if (isValidInteger(value)) {
      helpers.setValue(Number(value));
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const invalidKeys = ['e', 'E', '+', '-', '.', ',', ' '];
    if (invalidKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <div className='d-flex flex-column w-100'>
      <div className='position-relative'>
        <Field
          name={name}
          id={id}
          placeholder={t(placeholder)}
          className={`form-control fs-7 ${meta.touched && meta.error ? 'is-invalid' : ''}`}
          value={field.value || ''}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          disabled={disabled}
        />
      </div>
      <ErrorMessage name={name}>
        {msg => <div className='invalid-feedback d-block w-100'>{msg}</div>}
      </ErrorMessage>
    </div>
  );
};

export default NumberInput;
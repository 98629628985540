import React, {useEffect, useRef, useState} from 'react';
import { Link } from "react-router-dom";
import {
  ErrorMessage,
  FieldArray,
  Form,
  Formik,
} from 'formik';
import {Button, Col, Row, Container} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {ApolloError, useReactiveVar} from '@apollo/client';
import {currentCompanyIdVar} from '@me-team/host/src/apollo/globalVar/state';
import {useNavigate} from 'react-router-dom';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import TextInputWithCounter from '../inputs/TextInputWithCounter';
import {CustomModal, ReactSelect, Spinner, TextInput, TooltipCustom} from '@services/ui-components';
import TextareaInput from '../inputs/TextareaInput';
import CriticalResidueInput from '../inputs/CriticalResidueInput';
import {createProductFormValidationSchema} from '../constants';
import {
  CompanyBrandsProxy,
  ProductCategoryNewProxy,
  ProductInput,
  UnitGroupProxy
} from '@me-team/host/graphql/types';
import {
  useCreateProductCategoryMutation,
  useCreateProductMutation,
  useGetBrandsQuery,
  useGetProductCategoriesQuery, useGetProductsLazyQuery, useGetProductsQuery,
  useGetUnitsQuery
} from '@me-team/host/src/modules/products/graphql/products.hooks';
import ErrorService from '../../../services/ErrorService';
import {CategoryInterface, DuplicateProductData, Option, unitGroupI} from '../types';
import NumberInput from '../inputs/NumberInput';
import {ServiceModalWrapper} from '@me-pos/service-modal';
import TextInputWithButton from '../inputs/TextInputWithButton';
import ImageCrop from '@services/ui-components/src/ImageCrop/ImageCrop';

import CategoryProduct from '../services/CategoryProduct';
import RequestInputService from '../services/RequestInputService';
import {normalizeCategoryData} from '../services/normalizeCategoryData';

interface ValidationError {
  [key: string]: { message: string; code: string | null }[];
}

const CreateProductForm: React.FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const buttonCategoryRef = useRef<HTMLButtonElement>(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const [selectedUnitGroup, setSelectedUnitGroup] = useState(null);
  const [selectedAlternativeUnitGroup, setSelectedAlternativeUnitGroup] = useState(null);
  const [relationsUnits, setRelationsUnits] = useState(null);
  const [relationsAlternativeUnits, setRelationsAlternativeUnits] = useState(null);
  const [isProductModalOpen, setIsProductModalOpen] = useState<boolean>(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [categoryList, setCategoryList] = useState<CategoryInterface[]>(null)
  const [categoryInput, setCategoryInput] = useState(null);
  const [isDuplicateProduct, setIsDuplicateProduct] = useState<boolean>(false);
  const [pickedCategoryId, setPickedCategoryId] = useState<number>(null);
  const [duplicateProductData, setDuplicateProductData] = useState<DuplicateProductData | null>(null);

  const [
    categoryText,
    setCategoryText
  ] = useState([null, null]);
  const selectedCategory = categoryText[0] || '';
  const selectedSubcategory = categoryText[1 ] || '';

  const [createProductCategory] = useCreateProductCategoryMutation()
  const [createProduct] = useCreateProductMutation()

  const {
    data: productsData,
    refetch: refetchProducts
  } = useGetProductsQuery({
    context: {
      errorType: 'local'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
  });
  const [GetProducts, {data, refetch: productsRefetch}] = useGetProductsLazyQuery({
    context: {
      errorType: 'local'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
  });

  const {data: productCategoriesData, refetch: refetchCategories, loading: productCategoryLoading} = useGetProductCategoriesQuery({
    variables: {
      itemsPerPage: 1000,
      delete: false
    },
    context: {
      errorType: 'local'
    },
    onCompleted: (data) => {
      const normalizedItems = normalizeCategoryData(data);
      setCategoryList(CategoryProduct.categoryModuleCreator(normalizedItems, null, null))
      setCategoryInput(RequestInputService.createServiceCategoryInput(null, null, normalizedItems));
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
  });

  const categoryListData: ProductCategoryNewProxy[] = productCategoriesData?.productCategories?.items

  const {data: brandsData, loading: brandsLoading} = useGetBrandsQuery({
    variables: {
      companyId: +currentCompanyId,
    },
    context: {
      errorType: 'local'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    }
  });

  const suggestionsList: CompanyBrandsProxy[] = brandsData?.companyBrands

  const handleSubmitCreateCategory = async (variables: any) => {
    try {
      await createProductCategory({
        variables,
        onCompleted: (data) => {
          data && setCurrentCategory(data.createProductCategoryNew);
        },
        onError: (error) => {
          setIsErrorModalOpen(ErrorService.errorHandling(error))
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenDuplicateOfProductModal = () => {
    setIsDuplicateProduct(true)
  }
  const handleCloseDuplicateOfProductModal = () => {
    setIsDuplicateProduct(false)
  }

  const handleLabelClick = (event: React.MouseEvent<HTMLLabelElement>) => {
    event.preventDefault();
    if (buttonCategoryRef.current) {
      buttonCategoryRef.current.focus();
    }
  };

  const handleCategoryNameChange = (categoryName: string) => {
    setCategoryText([categoryName]);
  };

  const handleSubCategoryNameChange = (  subCategoryName: string, categoryName: string ) => {
    setCategoryText([categoryName, subCategoryName]);
  };

  const {data: unitsData} = useGetUnitsQuery({
    context: {
      errorType: 'local'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    }
  });

  const uniqueUnitGroups: UnitGroupProxy[] = unitsData?.relations?.units?.reduce((acc, unit) => {
    if (!acc.some(group => group.id === unit.unitGroup.id)) {
      acc.push(unit.unitGroup);
    }
    return acc;
  }, []);

  const quantityUnitGroupId = uniqueUnitGroups?.find(group => group.id === 2)?.id;
  const isQuantityUnitGroup = selectedUnitGroup?.value === quantityUnitGroupId;

  const unitGroupsWithoutQuantity = uniqueUnitGroups?.filter(
    (unitGroup: unitGroupI) => unitGroup?.id !== quantityUnitGroupId
  );

  const handleCancel = () => navigate(`/warehouse/product/${currentCompanyId}`)

  const unitGroupOptions = uniqueUnitGroups?.map((unitGroup: unitGroupI) => {
    return {
      value: unitGroup.id,
      label: unitGroup.name
    }
  }) || [];

  const unitsOptions = relationsUnits?.map((unit: unitGroupI) => {
    return {
      value: unit?.id || null,
      label: unit?.name || null
    }
  }) || [];

  const alternativeUnitGroupOptions = unitGroupsWithoutQuantity?.map((unitGroup: unitGroupI) => {
    return {
      value: unitGroup?.id,
      label: unitGroup?.name
    }
  }) || [];

  const alternativeUnitOptions = relationsAlternativeUnits?.map((unit: unitGroupI) => {
    return {
      value: unit?.id,
      label: unit?.name
    }
  }) || [];

  const handleUnitGroupChange = (selectedOption: Option, setFieldValue: any, setFieldError: any, setFieldTouched: any ) => {
    const filteredUnits = unitsData?.relations?.units?.filter(
      unit => unit?.unitGroup?.id === selectedOption.value
    );

    if (filteredUnits && filteredUnits.length > 0) {
      setRelationsUnits(filteredUnits);
      setFieldValue('unit', filteredUnits[0].id);
      setFieldTouched('unit', false);
    } else {
      setFieldValue('unit', null);
    }
    setFieldValue('unitGroup', selectedOption.value);
    setSelectedUnitGroup(selectedOption)
    setFieldTouched('unitGroup', false)
    if (selectedOption?.value !== 2) {
      setSelectedAlternativeUnitGroup(null)
    } else {
      setSelectedAlternativeUnitGroup(null);
      setFieldValue('alternativeUnitGroupInput', {
        alternativeUnitGroup: null,
        alternativeUnit: null,
        alternativeValue: null,
      });
    }
    setFieldError('alternativeUnitGroupInput.alternativeUnitGroup', null);
    setFieldError('alternativeUnitGroupInput.alternativeValue', null);
    setFieldError('alternativeUnitGroupInput.alternativeUnit', null);
  };

  const handleUnitChange = (selectedOption: Option, setFieldValue: any) => {
    setFieldValue('unit', selectedOption.value)
  };

  const handleAlternativeUnitGroupChange = (selectedOption: Option, setFieldValue: any, setFieldTouched:any) => {
    const filteredAlternativeUnits = unitsData?.relations?.units?.filter(
      unit => unit.unitGroup.id === selectedOption.value
    );
    if (filteredAlternativeUnits && filteredAlternativeUnits.length > 0) {
      setRelationsAlternativeUnits(filteredAlternativeUnits);
      setFieldValue('alternativeUnitGroupInput.alternativeUnit', filteredAlternativeUnits[0].id);
      setFieldTouched('alternativeUnitGroupInput.alternativeUnit', false);
    } else {
      setFieldValue('alternativeUnitGroupInput.alternativeUnit', null);
    }
    setFieldValue('alternativeUnitGroupInput.alternativeUnitGroup', selectedOption.value)
    setSelectedAlternativeUnitGroup(selectedOption)
  };

  const handleAlternativeUnitChange = (selectedOption: Option, setFieldValue: any) => {
    setFieldValue('alternativeUnitGroupInput.alternativeUnit', selectedOption.value)
  };

  const initialValues: ProductInput = {
    logo: null,
    name: '',
    brand: '',
    category: null,
    sku: '',
    barcode: '',
    description: '',
    unitGroup: null,
    unit: null,
    criticalResidue: null,
    alternativeUnitGroupInput: null,
    productAttributes: [],
    isUpdateLogo: false,
    deleted: false
  };

  const handleCreateSubmit = async (
    values: ProductInput,
  ) => {
      const input: ProductInput = {
      name: values?.name,
      brand: values?.brand,
      category: values?.category,
      sku: values?.sku || null,
      barcode: values?.barcode || null,
      description: values?.description || null,
      unitGroup: values?.unitGroup,
      unit: values?.unit,
      criticalResidue: values?.criticalResidue || null,
      alternativeUnitGroupInput: values?.unitGroup === 2 ? {
        alternativeUnitGroup: values?.alternativeUnitGroupInput?.alternativeUnitGroup,
        alternativeUnit: values?.alternativeUnitGroupInput?.alternativeUnit,
        alternativeValue: values?.alternativeUnitGroupInput?.alternativeValue,
      } : null,
      productAttributes: values?.productAttributes ? values?.productAttributes : null,
      isUpdateLogo: false,
      deleted: false
    }
    if (values?.isUpdateLogo === true) input.logo = values.logo

    await createProduct({
      variables: {companyId: +currentCompanyId, input},
      context: {
        errorType: 'local',
      },
      onCompleted: (data) => {
        productsRefetch()
        data && navigate(`/warehouse/product/${currentCompanyId}`)
      },
      onError: (error: ApolloError) => {
        console.error('error', error?.graphQLErrors[0]);
        const validationErrors = (
          error?.graphQLErrors[0]?.extensions as { validation?: ValidationError }
        )?.validation;

        const extractedData: Record<string, string> = {};

        if (validationErrors && validationErrors['']) {
          validationErrors['']?.forEach((err: any) => {
            const message: string = err.message;

            if (message.includes('The product already exists with')) {
              const regex = /name\s*=\s*"(.+?)"\s*,\s*brand\s*=\s*"(.+?)"\s*and\s*id\s*=\s*"(.+?)"/;
              const match = message.match(regex);

              if (match) {
                const [, name, brand, id] = match;
                extractedData.name = name;
                extractedData.brand = brand;
                extractedData.productId = id;
                setDuplicateProductData(extractedData);
                handleOpenDuplicateOfProductModal()
              }
            }
          })
        } else {
          setIsErrorModalOpen(ErrorService.errorHandling(error))
        }
      }
    })
  }

  const handlePhoto = (setFieldValue: any) => {  setFieldValue('isUpdateLogo', true)}

  const handleNavigateAndClose = () => {
    handleCloseDuplicateOfProductModal();
    navigate(`/warehouse/product/${currentCompanyId}/${duplicateProductData?.productId}/edit-product`);
  };

  if (brandsLoading || productCategoryLoading) return <Spinner/>;

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={createProductFormValidationSchema(t)}
        onSubmit={handleCreateSubmit}
      >
        {({
            setFieldValue,
            isValid,
            values,
            touched,
            errors,
            setFieldError,
            setFieldTouched
          }) => {
          useEffect(() => {
            pickedCategoryId && setFieldValue('category', pickedCategoryId);
          }, [pickedCategoryId]);

          return (
            <Form>
              <Row className='container-max-widths-xxl flex-column-reverse flex-lg-row  mb-5'>
                <Col
                  lg={8} xxl={9}
                  className='pe-xl-5'>
                  <h4 className='mb-3'>{t('Information')}</h4>
                  <Row className='mb-4 fs-7'>
                    <TextInputWithCounter
                      id='addProduct-name-field'
                      label={t('Name')}
                      name='name'
                      placeholder={t('Enter the Product Name')}
                    />
                  </Row>
                  <Row className='mb-4 fs-7'>
                    <TextInputWithCounter
                      id='addProduct-brand-field'
                      label={t('Brand')}
                      name='brand'
                      placeholder={t('Enter a Brand')}
                      suggestions={suggestionsList}
                    />
                  </Row>
                  <Row className='mb-4 fs-7'>
                    <TextInput
                      id='addProduct-sku-field'
                      name='sku'
                      placeholder='Enter a SKU'
                      label={t('ProductsList.SKU')}
                      maxLength={50}
                    />
                  </Row>
                  <Row className='mb-4 fs-7'>
                    <TextInput
                      id='addProduct-barcode-field'
                      name='barcode'
                      placeholder='Enter a Barcode'
                      label={t('ProductsList.Barcode')}
                      maxLength={20}
                    />
                  </Row>
                  <Row className='mb-4'>
                    <Col
                      md={2}
                      className='mb-2 mb-md-0'>
                      <label
                        htmlFor='addProduct-category-field'
                        className='text-dark fs-7 fw-bold py-1'
                        onClick={handleLabelClick}
                      >
                        {t('Category')}&nbsp;<span className='text-danger'>*</span>
                      </label>
                    </Col>
                    <Col md={10}>
                      <div>
                        <span className={`${selectedCategory ? 'me-4' : 'd-none'}  mb-2 mb-lg-0 text-dark`}>
                          {selectedCategory} {selectedSubcategory ?
                          <>
                            <i className="bi bi-arrow-right"></i> {selectedSubcategory}
                          </>
                          : null
                        }
                        </span>
                        <Button
                          ref={buttonCategoryRef}
                          variant={`${touched.category && errors.category ? 'outline-danger' : 'outline-primary'}`}
                          className='w-100 fw-normal py-1'
                          id='addService-category-field'
                          style={{maxWidth: '149px'}}
                          onClick={() => {
                            setIsProductModalOpen(true)
                          }}
                        >
                          {categoryText[0] ? t('Change Category') : t('Select Category')}
                        </Button>
                        <ErrorMessage name="category">{msg => <div
                          className='text-danger fs-7 mt-1'>{msg}</div>}
                        </ErrorMessage>
                      </div>
                    </Col>
                  </Row>
                  <Row className='mb-4 fs-7'>
                    <TextareaInput
                      label={t('Description')}
                      name='description'
                      id='addProduct-description-field'
                      placeholder={t('Enter a Description')}
                    />
                  </Row>
                  <Row className='mb-4 fs-7'>
                    <CriticalResidueInput
                      label={t('Critical Residue')}
                      name='criticalResidue'
                      id='addProduct-criticalResidue-field'
                      placeholder={t('Enter a Critical Residue')}
                      textTooltip={t('The number of products in stock, upon reaching which a warning email will be sent.')}
                    />
                  </Row>
                  <Row className='mb-4'>
                    <Col md={2} className='mb-2 mb-md-0 d-flex align-items-center'>
                      <label
                        htmlFor='unitGroup'
                        className='text-dark fs-7 fw-bold'>
                        {t('Type of Measure')}&nbsp;<span className='text-danger'>*</span>
                      </label>
                    </Col>
                    <Col md={10}>
                      <ReactSelect
                        id='addProduct-unitGroup-field'
                        name='unitGroup'
                        placeholder={t('Select an unit group')}
                        options={unitGroupOptions}
                        value={unitGroupOptions?.find((option) => option.value === values.unitGroup)}
                        onChange={(selectedOption: Option) => handleUnitGroupChange(selectedOption, setFieldValue, setFieldError, setFieldTouched)}
                        isSearchable={false}
                      />
                      <ErrorMessage name='unitGroup'>
                        {msg => <div className='invalid-feedback d-block'>{msg}</div>}
                      </ErrorMessage>
                    </Col>
                  </Row>
                  <Row className='mb-4'>
                    <Col md={2} className='mb-2 mb-md-0 d-flex align-items-center'>
                      <label
                        htmlFor='unit'
                        className='text-dark fs-7 fw-bold'>
                        {t('Unit')}&nbsp;<span className='text-danger'>*</span>
                      </label>
                    </Col>
                    <Col md={10}>
                      <ReactSelect
                        id='addProduct-unit-field'
                        name='unit'
                        placeholder={t('Select an unit')}
                        options={unitsOptions}
                        value={unitsOptions?.find((option: Option) => option.value === values.unit)}
                        onChange={(selectedOption: Option) => handleUnitChange(selectedOption, setFieldValue)}
                        isSearchable={false}
                        disabled={!selectedUnitGroup}
                      />
                      <ErrorMessage name='unit'>
                        {msg => <div className='invalid-feedback d-block'>{msg}</div>}
                      </ErrorMessage>
                    </Col>
                  </Row>
                  {
                    isQuantityUnitGroup
                      ?
                      <Row className='mb-4'>
                        <Col md={2} className='mb-2 mb-md-0 d-flex align-items-center'>
                          <label
                            htmlFor='alternativeUnitGroup'
                            className='text-dark fs-7 fw-bold'>
                            {t('Alternative measuring unit')}
                          </label>
                        </Col>
                        <Col md={10}>
                          <Row className='g-4'>
                            <Col xxl={5}>
                              <ReactSelect
                                id='addProduct-alternativeUnitGroup-field'
                                name='alternativeUnitGroupInput.alternativeUnitGroup'
                                placeholder={t('Select an unit group')}
                                options={alternativeUnitGroupOptions}
                                value={alternativeUnitGroupOptions?.find((option: Option) => option.value === values?.alternativeUnitGroupInput?.alternativeUnitGroup)}
                                onChange={(selectedOption: Option) => handleAlternativeUnitGroupChange(selectedOption, setFieldValue, setFieldTouched)}
                                isSearchable={false}
                              />
                              <ErrorMessage name='alternativeUnitGroupInput.alternativeUnitGroup'>
                                {msg => <div className='invalid-feedback d-block'>{msg}</div>}
                              </ErrorMessage>
                            </Col>
                            <Col xxl={4} className='mb-2 mb-md-0 d-flex'>
                              <label
                                htmlFor='alternativeUnitGroupInput.alternativeValue'
                                className='text-dark fs-7 fw-bold me-3 h-100 d-flex align-items-center'
                                style={{whiteSpace: 'nowrap', maxHeight: '50px'}}
                              >
                                <span>
                                  {t('1 piece =')}
                                </span>
                              </label>
                              <NumberInput
                                id='addProduct-alternativeUnitGroupInput.alternativeValue-field'
                                name='alternativeUnitGroupInput.alternativeValue'
                                placeholder={t('Add quantity')}
                                disabled={!selectedAlternativeUnitGroup}
                              />
                            </Col>
                            <Col xxl={3} className='mb-2 mb-md-0'>
                              <ReactSelect
                                id='addProduct-alternativeUnit-field'
                                name='alternativeUnitGroupInput.alternativeUnit'
                                placeholder={t('Select an unit')}
                                options={alternativeUnitOptions}
                                value={alternativeUnitOptions?.find((option: Option) => option.value === values?.alternativeUnitGroupInput?.alternativeUnit)}
                                onChange={(selectedOption: Option) => handleAlternativeUnitChange(selectedOption, setFieldValue)}
                                isSearchable={false}
                                disabled={!selectedAlternativeUnitGroup}
                              />
                              <ErrorMessage name='alternativeUnitGroupInput.alternativeUnit'>
                                {msg => <div className='invalid-feedback d-block'>{msg}</div>}
                              </ErrorMessage>
                            </Col>
                          </Row>

                        </Col>
                      </Row>
                      : null
                  }
                  <Row className='pt-6'>
                    <Col>
                      <Row className='d-flex align-items-center justify-content-between'>
                        <Col md={5} className='d-flex align-items-center'>
                          <h5 className='mb-0'>
                            {t('Additional product attributes')}
                          </h5>
                          <TooltipCustom placement='right' text={t('You can add your own product' +
                            ' properties, for example: Color, Tone, Used with, etc.')}>
                            <i className='bi bi-info-circle text-dark ms-3'></i>
                          </TooltipCustom>
                        </Col>
                        <Col md={1} className='d-flex justify-content-end'>
                          <TooltipCustom placement='bottom' text={t('Add attribute')}>
                            <Button
                              variant='outline-secondary'
                              className='btn-square'
                              style={{width: '50px', height: '50px'}}
                              onClick={() =>
                                setFieldValue('productAttributes', [
                                  ...values.productAttributes,
                                  {name: '', property: ''},
                                ])
                              }
                            >
                              <i className='bi bi-plus-lg text-dark'></i>
                            </Button>
                          </TooltipCustom>
                        </Col>
                      </Row>
                      {values?.productAttributes?.length > 0 ? (
                        <Row className='mt-3'>
                          <Col xs={9} md={12}>
                            <FieldArray
                              name="productAttributes"
                              render={({remove}) => (
                                <>
                                  {values?.productAttributes?.map((_: any, index: number) => {
                                    return (
                                      <div key={index} className="d-flex flex-column mb-3">
                                        <Row className='mb-4 fs-7'>
                                          <TextInput
                                            id={`addProduct-productAttributes.${index}.name-field`}
                                            name={`productAttributes.${index}.name`}
                                            placeholder={t('Attributes.Add title')}
                                            label={t('Name')}
                                            required={true}
                                            maxLength={20}
                                          />
                                        </Row>
                                        <TextInputWithButton
                                          id={`addProduct-productAttributes.${index}.name-field`}
                                          name={`productAttributes.${index}.property`}
                                          index={index}
                                          remove={remove}
                                          label={t('Property')}
                                        />
                                      </div>
                                    )
                                  })}
                                </>
                              )}
                            />
                          </Col>
                        </Row>
                      ) : null}
                    </Col>
                  </Row>
                </Col>
                <Col lg={4} xxl={3}>
                  <div
                    className='d-flex mb-3 w-100 flex-column  justify-content-lg-end'>
                    <h4 className='mb-3 w-100'>
                      {t('Product\'s photo')}
                    </h4>
                    <ImageCrop
                      setFieldValue={setFieldValue}
                      fieldName="logo"
                      modalTitle={t('Product\'s photo')}
                      notBGinfo={true}
                      fieldSetter={() => handlePhoto(setFieldValue)}
                    />
                    <ErrorMessage name="logo">{msg => <div>{msg}</div>}</ErrorMessage>
                  </div>
                </Col>
              </Row>
              <Row className='mt-2 mt-lg-5'>
                <Col lg={4} className='d-flex gap-4'>
                  <Button
                    type='button'
                    variant='outline-primary'
                    className='custom-btn'
                    onClick={handleCancel}
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    type='submit'
                    variant='primary'
                    className='custom-btn'
                  >
                    {t('Save')}
                  </Button>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
      {
        isProductModalOpen && productCategoriesData && !productCategoryLoading ?
          <ServiceModalWrapper
            i18n={i18next}
            selectedCategoryId={null}
            selectedSubcategoryId={null}
            apolloUrl={process.env.REACT_APP_API_URL}
            isOpen={isProductModalOpen}
            onClose={() => {
              setIsProductModalOpen(!isProductModalOpen)
            }}
            onCategorySelect={(selectedCategory) => {
              handleCategoryNameChange(selectedCategory?.name);
              setPickedCategoryId(selectedCategory?.id)
              setIsProductModalOpen(!isProductModalOpen)
            }}
            onSubcategorySelect={(selectedSubcategory) => {
              handleSubCategoryNameChange(
                selectedSubcategory?.name,
                selectedSubcategory?.parentCategory?.name
              );
              setPickedCategoryId(selectedSubcategory?.id)
              setIsProductModalOpen(!isProductModalOpen)
            }}
            categoryList={categoryList}
            categoryInput={categoryInput}
            categoryListData={categoryListData}
            refetchCategories={refetchCategories}
            onSubmitCreate={handleSubmitCreateCategory}
            currentCategory={currentCategory}
            categoryService={CategoryProduct}
            requestInputService={RequestInputService}
            normalizeCategoryData={normalizeCategoryData}
          />
          : null
      }
      <CustomModal
        show={isDuplicateProduct}
        handleClose={handleCloseDuplicateOfProductModal}
        title={
          <Container>
            <Row>
              <h5 className='m-0 p-0 fw-normal'>{t('The product already exists')}</h5>
            </Row>
          </Container>
        }
        body={
          <Container>
            <Row>
              <Col>
                <span className='fs-16 me-1'>{t('Such a product already exists, you can' +
                  ' familiarize yourself with it at the link')}
                </span>
                <div>
                  <Button
                    variant='link'
                    className='fs-normal text-primary text-decoration-underline cursor-pointer'
                    onClick={handleNavigateAndClose}
                  >
                      {`${duplicateProductData?.brand} ${duplicateProductData?.name}`}
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        }
        buttons={[
          {
            text: t('OK'),
            onClick: handleCloseDuplicateOfProductModal,
            variant: 'outline-primary',
            className: 'w-100 fw-normal',
          }
        ]}
      />
      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </>
  );
};

export default CreateProductForm;
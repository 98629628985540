import {CSSObjectWithLabel, StylesConfig} from 'react-select';
import React from 'react';

const customStyles = (error:any, touched:any) => {
    return {
        control: (provided: CSSObjectWithLabel, {isFocused}: {isFocused: Boolean}) => {
            const defaultBorderColor = isFocused ? '#ACA1DF' : '#DEDEDE';
            const borderColor = (touched && error) ? '#DC3545' : defaultBorderColor;
            return {
                ...provided,
                borderColor: borderColor,
                padding: "6px 0px",
                borderRadius: '4px',
                minHeight: '100%',
                boxShadow: isFocused ? '0 0 0 0.2rem rgba(111, 66, 193, 0.25)' : 'none',
                '&:hover': {
                    borderColor: '#ACA1DF',
                },
            }
        },

        option: (provided: CSSObjectWithLabel, {isSelected}: {isSelected: Boolean}) => ({
            ...provided,
            borderRadius: "4px",
            marginBottom: "8px",
            padding: "4px 12px",
            cursor: "pointer",
            backgroundColor: isSelected ? '#5842BE' : '#FFFFFF',
            color: isSelected ? '#FFFFFF' : '#1F3354',
            '&:hover': {
                backgroundColor: '#EEECF9',
                color: '#1F3354',
            },
        }),

        placeholder: (provided: CSSObjectWithLabel) => ({
            ...provided,
            fontSize: "14px",
            color: "#9D9D9D",
            margin: "0px 4px",
        }),

        input: (provided: CSSObjectWithLabel) => ({
            ...provided,
            fontSize: "14px",
            color: "#1F3354",
        }),

        dropdownIndicator: (provided: CSSObjectWithLabel, {selectProps}: any) => ({
            ...provided,
            transform: selectProps.menuIsOpen ? 'rotate(180deg)' : null,
            transition: 'transform 0.2s ease',
        }),

        menu: (provided: CSSObjectWithLabel) => ({
            ...provided,
            marginTop: "4px",
            zIndex: "600"
        }),

        menuList: (provided: CSSObjectWithLabel) => ({
            ...provided,
            padding: '8px 12px',
        }),

        indicatorSeparator: () => ({
            display: 'none',
        }),

        singleValue: (provided: CSSObjectWithLabel) => ({
            ...provided,
            fontSize: "14px",
        })
    }
}
export default customStyles;

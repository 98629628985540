/* eslint-disable */
import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetWarehousesDocument = gql`
    query GetWarehouses($page: Int!, $itemsPerPage: Int!, $currentBranchId: Int!) {
  user {
    company {
      branchesPaginated(id: $currentBranchId) {
        branches {
          storagesPaginated(page: $page, itemsPerPage: $itemsPerPage) {
            storages {
              id
              name
              description
              deleted
              type {
                id
                name
              }
              storageProductRelations {
                id
                quantity
              }
              default
            }
            totalCount
            currentPage
            itemsPerPage
            pageCount
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetWarehousesQuery__
 *
 * To run a query within a React component, call `useGetWarehousesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarehousesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarehousesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      currentBranchId: // value for 'currentBranchId'
 *   },
 * });
 */
export function useGetWarehousesQuery(baseOptions: Apollo.QueryHookOptions<GetWarehousesQuery, GetWarehousesQueryVariables> & ({ variables: GetWarehousesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWarehousesQuery, GetWarehousesQueryVariables>(GetWarehousesDocument, options);
      }
export function useGetWarehousesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWarehousesQuery, GetWarehousesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWarehousesQuery, GetWarehousesQueryVariables>(GetWarehousesDocument, options);
        }
export function useGetWarehousesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetWarehousesQuery, GetWarehousesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetWarehousesQuery, GetWarehousesQueryVariables>(GetWarehousesDocument, options);
        }
export type GetWarehousesQueryHookResult = ReturnType<typeof useGetWarehousesQuery>;
export type GetWarehousesLazyQueryHookResult = ReturnType<typeof useGetWarehousesLazyQuery>;
export type GetWarehousesSuspenseQueryHookResult = ReturnType<typeof useGetWarehousesSuspenseQuery>;
export type GetWarehousesQueryResult = Apollo.QueryResult<GetWarehousesQuery, GetWarehousesQueryVariables>;
export const GetStorageTypesDocument = gql`
    query GetStorageTypes {
  relations {
    storageTypes {
      id
      name
    }
  }
}
    `;

/**
 * __useGetStorageTypesQuery__
 *
 * To run a query within a React component, call `useGetStorageTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStorageTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStorageTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStorageTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetStorageTypesQuery, GetStorageTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStorageTypesQuery, GetStorageTypesQueryVariables>(GetStorageTypesDocument, options);
      }
export function useGetStorageTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStorageTypesQuery, GetStorageTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStorageTypesQuery, GetStorageTypesQueryVariables>(GetStorageTypesDocument, options);
        }
export function useGetStorageTypesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetStorageTypesQuery, GetStorageTypesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStorageTypesQuery, GetStorageTypesQueryVariables>(GetStorageTypesDocument, options);
        }
export type GetStorageTypesQueryHookResult = ReturnType<typeof useGetStorageTypesQuery>;
export type GetStorageTypesLazyQueryHookResult = ReturnType<typeof useGetStorageTypesLazyQuery>;
export type GetStorageTypesSuspenseQueryHookResult = ReturnType<typeof useGetStorageTypesSuspenseQuery>;
export type GetStorageTypesQueryResult = Apollo.QueryResult<GetStorageTypesQuery, GetStorageTypesQueryVariables>;
export const CreateStorageDocument = gql`
    mutation CreateStorage($branchId: Int!, $input: StorageInput!) {
  createStorage(branchId: $branchId, input: $input) {
    id
    name
  }
}
    `;
export type CreateStorageMutationFn = Apollo.MutationFunction<CreateStorageMutation, CreateStorageMutationVariables>;

/**
 * __useCreateStorageMutation__
 *
 * To run a mutation, you first call `useCreateStorageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStorageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStorageMutation, { data, loading, error }] = useCreateStorageMutation({
 *   variables: {
 *      branchId: // value for 'branchId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStorageMutation(baseOptions?: Apollo.MutationHookOptions<CreateStorageMutation, CreateStorageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStorageMutation, CreateStorageMutationVariables>(CreateStorageDocument, options);
      }
export type CreateStorageMutationHookResult = ReturnType<typeof useCreateStorageMutation>;
export type CreateStorageMutationResult = Apollo.MutationResult<CreateStorageMutation>;
export type CreateStorageMutationOptions = Apollo.BaseMutationOptions<CreateStorageMutation, CreateStorageMutationVariables>;
export const UpdateStorageDocument = gql`
    mutation UpdateStorage($storageId: Int!, $input: StorageInput!) {
  updateStorage(storageId: $storageId, input: $input) {
    id
    name
  }
}
    `;
export type UpdateStorageMutationFn = Apollo.MutationFunction<UpdateStorageMutation, UpdateStorageMutationVariables>;

/**
 * __useUpdateStorageMutation__
 *
 * To run a mutation, you first call `useUpdateStorageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStorageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStorageMutation, { data, loading, error }] = useUpdateStorageMutation({
 *   variables: {
 *      storageId: // value for 'storageId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStorageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStorageMutation, UpdateStorageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStorageMutation, UpdateStorageMutationVariables>(UpdateStorageDocument, options);
      }
export type UpdateStorageMutationHookResult = ReturnType<typeof useUpdateStorageMutation>;
export type UpdateStorageMutationResult = Apollo.MutationResult<UpdateStorageMutation>;
export type UpdateStorageMutationOptions = Apollo.BaseMutationOptions<UpdateStorageMutation, UpdateStorageMutationVariables>;
export type GetWarehousesQueryVariables = Types.Exact<{
  page: Types.Scalars['Int']['input'];
  itemsPerPage: Types.Scalars['Int']['input'];
  currentBranchId: Types.Scalars['Int']['input'];
}>;


export type GetWarehousesQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', company?: { __typename?: 'CompanyProxy', branchesPaginated?: { __typename?: 'BranchPaginated', branches?: Array<{ __typename?: 'BranchProxy', storagesPaginated?: { __typename?: 'StoragePaginated', totalCount?: number | null, currentPage?: number | null, itemsPerPage?: number | null, pageCount?: string | null, storages?: Array<{ __typename?: 'StorageProxy', id: number, name: string, description?: string | null, deleted: boolean, default: boolean, type?: { __typename?: 'StorageTypeProxy', id: number, name: string } | null, storageProductRelations?: Array<{ __typename?: 'StorageProductRelationProxy', id: number, quantity?: number | null }> | null }> | null } | null }> | null } | null } | null } | null };

export type GetStorageTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetStorageTypesQuery = { __typename?: 'Query', relations: { __typename?: 'RelationProxy', storageTypes?: Array<{ __typename?: 'StorageTypeProxy', id: number, name: string }> | null } };

export type CreateStorageMutationVariables = Types.Exact<{
  branchId: Types.Scalars['Int']['input'];
  input: Types.StorageInput;
}>;


export type CreateStorageMutation = { __typename?: 'Mutation', createStorage: { __typename?: 'StorageProxy', id: number, name: string } };

export type UpdateStorageMutationVariables = Types.Exact<{
  storageId: Types.Scalars['Int']['input'];
  input: Types.StorageInput;
}>;


export type UpdateStorageMutation = { __typename?: 'Mutation', updateStorage: { __typename?: 'StorageProxy', id: number, name: string } };

import React from 'react';
import Select from "react-select";
import { useFormikContext, getIn } from 'formik';
import customStyles from "./customStyles";
import { CustomControlIconError } from './CustomControlIconError';

interface Option {
  value: string | number | string[];
  label: string;
}

interface ReactSelectProps {
  name?: string;
  value?: Option | number | null;
  options: Array<any>;
  placeholder: string;
  onChange: (option: Option | null) => void;
  isSearchable: boolean;
  disabled?: boolean;
  key?: string;
  stylesSelect?: any;
  menuPlacement?: string;
  menuPortalTarget?: HTMLElement;
  id?: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;

}

const filterOption = (option: Option, inputValue: string) => {
  return option.label.toLowerCase().includes(inputValue.toLowerCase());
};

const ReactSelect: React.FC<ReactSelectProps> = ({
                                                   value,
                                                   options,
                                                   placeholder,
                                                   onChange,
                                                   isSearchable,
                                                   disabled = false,
                                                   key,
                                                   name,
                                                   menuPlacement,
                                                   stylesSelect,
                                                   menuPortalTarget,
                                                   id,
                                                   onKeyDown
                                                 }) => {

  // If select used in Formik, then we pass it to 'name' and then we can use the useFormikContext
  let errors: any;
  let touched: any;

  if (name) {
    const formikContext = useFormikContext<any>();
    // `getIn` is used to handle both regular and nested field names
    errors = formikContext?.errors && getIn(formikContext.errors, name);
    touched = formikContext?.touched && getIn(formikContext.touched, name);
  }

  return (
    <div>
      <Select
        id={id}
        key={key}
        inputId={name}
        name={name}
        value={value}
        options={options}
        placeholder={placeholder}
        onChange={onChange}
        isSearchable={isSearchable}
        styles={stylesSelect ? stylesSelect : customStyles(errors, touched)}
        isDisabled={disabled}
        filterOption={filterOption}
        menuPlacement={menuPlacement}
        menuPortalTarget={menuPortalTarget}
        components={{ Control: CustomControlIconError }}
        onKeyDown={onKeyDown}
        error={errors && touched}
      />
    </div>
  );
};

export default ReactSelect;

import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem'
import {TechnologicalMapProductRelationProxy} from '@me-team/host/graphql/types';
import {Link} from 'react-router-dom';

interface TechnologicalMapsListProps {
  technologicalMaps: TechnologicalMapProductRelationProxy[];
}

const TechnologicalMapsList: React.FC<TechnologicalMapsListProps> = ({technologicalMaps}) => {
  return (
    <>
      {technologicalMaps ?
        <ListGroup>
          {technologicalMaps?.map((techMap: TechnologicalMapProductRelationProxy, i: number ) => (
            <ListGroupItem key={techMap?.id}>
              <span className='text-primary'>{i + 1}. </span>
              {/*TODO добавить ссылку на тех карты, когда страница с ними будет готова*/}
              <Link to={'/'} className='text-primary'>
                {techMap?.technologicalMap?.name}
              </Link>
            </ListGroupItem>
          ))}
        </ListGroup>
        :
        null
      }
    </>
  );
};

export default TechnologicalMapsList;
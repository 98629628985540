/* eslint-disable */
import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetEmployeesDocument = gql`
    query GetEmployees($page: Int!, $itemsPerPage: Int!, $name: String, $positionId: Int, $statusId: Int) {
  role {
    id
    name
    isOwner
  }
  user {
    id
    email
    company {
      paidFeatures {
        id
        name
        dateOfEnd
      }
      employees(
        page: $page
        itemsPerPage: $itemsPerPage
        name: $name
        positionId: $positionId
        statusId: $statusId
      ) {
        totalCount
        currentPage
        pageCount
        employees {
          id
          status {
            id
            name
          }
          name
          surname
          email
          phone
          position {
            id
            name
          }
          role {
            id
            name
          }
          tariffBlocking
          manualBlocking
          employeeServiceRelations {
            id
          }
          workSchedules {
            id
            startDate
            endDate
          }
          specialization
          userBranchRelations {
            branch {
              id
            }
          }
          reviews {
            id
            name
            rating
          }
          recordSetting {
            id
            isDefaultEmployee
            isOnlineSignUpAvailable
            isReviewsAvailable
          }
          qualificationConfirmations {
            id
          }
          dismissalEmployee {
            id
          }
          image
          contract
          qualificationConfirmations {
            id
            image
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetEmployeesQuery__
 *
 * To run a query within a React component, call `useGetEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      name: // value for 'name'
 *      positionId: // value for 'positionId'
 *      statusId: // value for 'statusId'
 *   },
 * });
 */
export function useGetEmployeesQuery(baseOptions: Apollo.QueryHookOptions<GetEmployeesQuery, GetEmployeesQueryVariables> & ({ variables: GetEmployeesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeesQuery, GetEmployeesQueryVariables>(GetEmployeesDocument, options);
      }
export function useGetEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeesQuery, GetEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeesQuery, GetEmployeesQueryVariables>(GetEmployeesDocument, options);
        }
export function useGetEmployeesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEmployeesQuery, GetEmployeesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEmployeesQuery, GetEmployeesQueryVariables>(GetEmployeesDocument, options);
        }
export type GetEmployeesQueryHookResult = ReturnType<typeof useGetEmployeesQuery>;
export type GetEmployeesLazyQueryHookResult = ReturnType<typeof useGetEmployeesLazyQuery>;
export type GetEmployeesSuspenseQueryHookResult = ReturnType<typeof useGetEmployeesSuspenseQuery>;
export type GetEmployeesQueryResult = Apollo.QueryResult<GetEmployeesQuery, GetEmployeesQueryVariables>;
export const GetEmployeesTariffBlockingsDocument = gql`
    query GetEmployeesTariffBlockings {
  user {
    company {
      employees {
        employees {
          id
          tariffBlocking
          position {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetEmployeesTariffBlockingsQuery__
 *
 * To run a query within a React component, call `useGetEmployeesTariffBlockingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeesTariffBlockingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeesTariffBlockingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmployeesTariffBlockingsQuery(baseOptions?: Apollo.QueryHookOptions<GetEmployeesTariffBlockingsQuery, GetEmployeesTariffBlockingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeesTariffBlockingsQuery, GetEmployeesTariffBlockingsQueryVariables>(GetEmployeesTariffBlockingsDocument, options);
      }
export function useGetEmployeesTariffBlockingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeesTariffBlockingsQuery, GetEmployeesTariffBlockingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeesTariffBlockingsQuery, GetEmployeesTariffBlockingsQueryVariables>(GetEmployeesTariffBlockingsDocument, options);
        }
export function useGetEmployeesTariffBlockingsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEmployeesTariffBlockingsQuery, GetEmployeesTariffBlockingsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEmployeesTariffBlockingsQuery, GetEmployeesTariffBlockingsQueryVariables>(GetEmployeesTariffBlockingsDocument, options);
        }
export type GetEmployeesTariffBlockingsQueryHookResult = ReturnType<typeof useGetEmployeesTariffBlockingsQuery>;
export type GetEmployeesTariffBlockingsLazyQueryHookResult = ReturnType<typeof useGetEmployeesTariffBlockingsLazyQuery>;
export type GetEmployeesTariffBlockingsSuspenseQueryHookResult = ReturnType<typeof useGetEmployeesTariffBlockingsSuspenseQuery>;
export type GetEmployeesTariffBlockingsQueryResult = Apollo.QueryResult<GetEmployeesTariffBlockingsQuery, GetEmployeesTariffBlockingsQueryVariables>;
export const GetEmployeesPositionsDocument = gql`
    query GetEmployeesPositions {
  user {
    company {
      employeePositions {
        employeePositions {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetEmployeesPositionsQuery__
 *
 * To run a query within a React component, call `useGetEmployeesPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeesPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeesPositionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmployeesPositionsQuery(baseOptions?: Apollo.QueryHookOptions<GetEmployeesPositionsQuery, GetEmployeesPositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeesPositionsQuery, GetEmployeesPositionsQueryVariables>(GetEmployeesPositionsDocument, options);
      }
export function useGetEmployeesPositionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeesPositionsQuery, GetEmployeesPositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeesPositionsQuery, GetEmployeesPositionsQueryVariables>(GetEmployeesPositionsDocument, options);
        }
export function useGetEmployeesPositionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEmployeesPositionsQuery, GetEmployeesPositionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEmployeesPositionsQuery, GetEmployeesPositionsQueryVariables>(GetEmployeesPositionsDocument, options);
        }
export type GetEmployeesPositionsQueryHookResult = ReturnType<typeof useGetEmployeesPositionsQuery>;
export type GetEmployeesPositionsLazyQueryHookResult = ReturnType<typeof useGetEmployeesPositionsLazyQuery>;
export type GetEmployeesPositionsSuspenseQueryHookResult = ReturnType<typeof useGetEmployeesPositionsSuspenseQuery>;
export type GetEmployeesPositionsQueryResult = Apollo.QueryResult<GetEmployeesPositionsQuery, GetEmployeesPositionsQueryVariables>;
export const GetStatusDocument = gql`
    query GetStatus {
  employeeStatus {
    id
    name
  }
}
    `;

/**
 * __useGetStatusQuery__
 *
 * To run a query within a React component, call `useGetStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStatusQuery(baseOptions?: Apollo.QueryHookOptions<GetStatusQuery, GetStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStatusQuery, GetStatusQueryVariables>(GetStatusDocument, options);
      }
export function useGetStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStatusQuery, GetStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStatusQuery, GetStatusQueryVariables>(GetStatusDocument, options);
        }
export function useGetStatusSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetStatusQuery, GetStatusQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStatusQuery, GetStatusQueryVariables>(GetStatusDocument, options);
        }
export type GetStatusQueryHookResult = ReturnType<typeof useGetStatusQuery>;
export type GetStatusLazyQueryHookResult = ReturnType<typeof useGetStatusLazyQuery>;
export type GetStatusSuspenseQueryHookResult = ReturnType<typeof useGetStatusSuspenseQuery>;
export type GetStatusQueryResult = Apollo.QueryResult<GetStatusQuery, GetStatusQueryVariables>;
export const GetCompanyIdDocument = gql`
    query GetCompanyId {
  user {
    id
    company {
      id
      name
    }
  }
}
    `;

/**
 * __useGetCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyIdQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyIdQuery, GetCompanyIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyIdQuery, GetCompanyIdQueryVariables>(GetCompanyIdDocument, options);
      }
export function useGetCompanyIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyIdQuery, GetCompanyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyIdQuery, GetCompanyIdQueryVariables>(GetCompanyIdDocument, options);
        }
export function useGetCompanyIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCompanyIdQuery, GetCompanyIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCompanyIdQuery, GetCompanyIdQueryVariables>(GetCompanyIdDocument, options);
        }
export type GetCompanyIdQueryHookResult = ReturnType<typeof useGetCompanyIdQuery>;
export type GetCompanyIdLazyQueryHookResult = ReturnType<typeof useGetCompanyIdLazyQuery>;
export type GetCompanyIdSuspenseQueryHookResult = ReturnType<typeof useGetCompanyIdSuspenseQuery>;
export type GetCompanyIdQueryResult = Apollo.QueryResult<GetCompanyIdQuery, GetCompanyIdQueryVariables>;
export const ChangeTariffBlockingDocument = gql`
    mutation ChangeTariffBlocking($id: Int!, $name: String!, $surname: String!, $email: String!, $phone: String!, $specialization: String!, $employeePosition: Int!, $role: Int!, $tariffBlocking: Boolean!, $manualBlocking: Boolean!, $status: Int!, $isUpdateAccess: Boolean) {
  updateEmployee(
    id: $id
    input: {company: $id, name: $name, surname: $surname, email: $email, phone: $phone, specialization: $specialization, employeePosition: $employeePosition, role: $role, tariffBlocking: $tariffBlocking, manualBlocking: $manualBlocking, status: $status, isUpdateAccess: $isUpdateAccess}
  ) {
    id
    name
    tariffBlocking
    manualBlocking
  }
}
    `;
export type ChangeTariffBlockingMutationFn = Apollo.MutationFunction<ChangeTariffBlockingMutation, ChangeTariffBlockingMutationVariables>;

/**
 * __useChangeTariffBlockingMutation__
 *
 * To run a mutation, you first call `useChangeTariffBlockingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTariffBlockingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTariffBlockingMutation, { data, loading, error }] = useChangeTariffBlockingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      surname: // value for 'surname'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      specialization: // value for 'specialization'
 *      employeePosition: // value for 'employeePosition'
 *      role: // value for 'role'
 *      tariffBlocking: // value for 'tariffBlocking'
 *      manualBlocking: // value for 'manualBlocking'
 *      status: // value for 'status'
 *      isUpdateAccess: // value for 'isUpdateAccess'
 *   },
 * });
 */
export function useChangeTariffBlockingMutation(baseOptions?: Apollo.MutationHookOptions<ChangeTariffBlockingMutation, ChangeTariffBlockingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeTariffBlockingMutation, ChangeTariffBlockingMutationVariables>(ChangeTariffBlockingDocument, options);
      }
export type ChangeTariffBlockingMutationHookResult = ReturnType<typeof useChangeTariffBlockingMutation>;
export type ChangeTariffBlockingMutationResult = Apollo.MutationResult<ChangeTariffBlockingMutation>;
export type ChangeTariffBlockingMutationOptions = Apollo.BaseMutationOptions<ChangeTariffBlockingMutation, ChangeTariffBlockingMutationVariables>;
export const UpdateEmployeeRecordSettingsDocument = gql`
    mutation UpdateEmployeeRecordSettings($id: Int!, $isOnlineSignUpAvailable: Boolean!, $isDefaultEmployee: Boolean!, $isReviewsAvailable: Boolean!) {
  updateEmployeeRecordSettings(
    id: $id
    input: {isOnlineSignUpAvailable: $isOnlineSignUpAvailable, isDefaultEmployee: $isDefaultEmployee, isReviewsAvailable: $isReviewsAvailable}
  ) {
    id
    isOnlineSignUpAvailable
    isDefaultEmployee
    isReviewsAvailable
  }
}
    `;
export type UpdateEmployeeRecordSettingsMutationFn = Apollo.MutationFunction<UpdateEmployeeRecordSettingsMutation, UpdateEmployeeRecordSettingsMutationVariables>;

/**
 * __useUpdateEmployeeRecordSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeRecordSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeRecordSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeRecordSettingsMutation, { data, loading, error }] = useUpdateEmployeeRecordSettingsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isOnlineSignUpAvailable: // value for 'isOnlineSignUpAvailable'
 *      isDefaultEmployee: // value for 'isDefaultEmployee'
 *      isReviewsAvailable: // value for 'isReviewsAvailable'
 *   },
 * });
 */
export function useUpdateEmployeeRecordSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeRecordSettingsMutation, UpdateEmployeeRecordSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeeRecordSettingsMutation, UpdateEmployeeRecordSettingsMutationVariables>(UpdateEmployeeRecordSettingsDocument, options);
      }
export type UpdateEmployeeRecordSettingsMutationHookResult = ReturnType<typeof useUpdateEmployeeRecordSettingsMutation>;
export type UpdateEmployeeRecordSettingsMutationResult = Apollo.MutationResult<UpdateEmployeeRecordSettingsMutation>;
export type UpdateEmployeeRecordSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeRecordSettingsMutation, UpdateEmployeeRecordSettingsMutationVariables>;
export const DismissEmployeeDocument = gql`
    mutation DismissEmployee($employeeId: Int!, $endOfCooperation: String!, $reason: Int!, $comment: String!) {
  dismissEmployee(
    employeeId: $employeeId
    input: {endOfCooperation: $endOfCooperation, reason: $reason, comment: $comment}
  ) {
    id
  }
}
    `;
export type DismissEmployeeMutationFn = Apollo.MutationFunction<DismissEmployeeMutation, DismissEmployeeMutationVariables>;

/**
 * __useDismissEmployeeMutation__
 *
 * To run a mutation, you first call `useDismissEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissEmployeeMutation, { data, loading, error }] = useDismissEmployeeMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      endOfCooperation: // value for 'endOfCooperation'
 *      reason: // value for 'reason'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useDismissEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<DismissEmployeeMutation, DismissEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DismissEmployeeMutation, DismissEmployeeMutationVariables>(DismissEmployeeDocument, options);
      }
export type DismissEmployeeMutationHookResult = ReturnType<typeof useDismissEmployeeMutation>;
export type DismissEmployeeMutationResult = Apollo.MutationResult<DismissEmployeeMutation>;
export type DismissEmployeeMutationOptions = Apollo.BaseMutationOptions<DismissEmployeeMutation, DismissEmployeeMutationVariables>;
export const DeleteDismissEmployeeDocument = gql`
    mutation DeleteDismissEmployee($id: Int!) {
  deleteDismissEmployee(id: $id)
}
    `;
export type DeleteDismissEmployeeMutationFn = Apollo.MutationFunction<DeleteDismissEmployeeMutation, DeleteDismissEmployeeMutationVariables>;

/**
 * __useDeleteDismissEmployeeMutation__
 *
 * To run a mutation, you first call `useDeleteDismissEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDismissEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDismissEmployeeMutation, { data, loading, error }] = useDeleteDismissEmployeeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDismissEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDismissEmployeeMutation, DeleteDismissEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDismissEmployeeMutation, DeleteDismissEmployeeMutationVariables>(DeleteDismissEmployeeDocument, options);
      }
export type DeleteDismissEmployeeMutationHookResult = ReturnType<typeof useDeleteDismissEmployeeMutation>;
export type DeleteDismissEmployeeMutationResult = Apollo.MutationResult<DeleteDismissEmployeeMutation>;
export type DeleteDismissEmployeeMutationOptions = Apollo.BaseMutationOptions<DeleteDismissEmployeeMutation, DeleteDismissEmployeeMutationVariables>;
export const GetDismissalReasonDocument = gql`
    query GetDismissalReason {
  dismissalReason {
    id
    name
  }
}
    `;

/**
 * __useGetDismissalReasonQuery__
 *
 * To run a query within a React component, call `useGetDismissalReasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDismissalReasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDismissalReasonQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDismissalReasonQuery(baseOptions?: Apollo.QueryHookOptions<GetDismissalReasonQuery, GetDismissalReasonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDismissalReasonQuery, GetDismissalReasonQueryVariables>(GetDismissalReasonDocument, options);
      }
export function useGetDismissalReasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDismissalReasonQuery, GetDismissalReasonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDismissalReasonQuery, GetDismissalReasonQueryVariables>(GetDismissalReasonDocument, options);
        }
export function useGetDismissalReasonSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDismissalReasonQuery, GetDismissalReasonQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDismissalReasonQuery, GetDismissalReasonQueryVariables>(GetDismissalReasonDocument, options);
        }
export type GetDismissalReasonQueryHookResult = ReturnType<typeof useGetDismissalReasonQuery>;
export type GetDismissalReasonLazyQueryHookResult = ReturnType<typeof useGetDismissalReasonLazyQuery>;
export type GetDismissalReasonSuspenseQueryHookResult = ReturnType<typeof useGetDismissalReasonSuspenseQuery>;
export type GetDismissalReasonQueryResult = Apollo.QueryResult<GetDismissalReasonQuery, GetDismissalReasonQueryVariables>;
export const GetEmployeesPositionsTableDocument = gql`
    query GetEmployeesPositionsTable($page: Int!, $items: Int!) {
  user {
    company {
      employeePositions(page: $page, itemsPerPage: $items) {
        employeePositions {
          id
          name
          description
          employees {
            id
            specialization
          }
        }
        totalCount
        currentPage
        itemsPerPage
        pageCount
      }
    }
  }
}
    `;

/**
 * __useGetEmployeesPositionsTableQuery__
 *
 * To run a query within a React component, call `useGetEmployeesPositionsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeesPositionsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeesPositionsTableQuery({
 *   variables: {
 *      page: // value for 'page'
 *      items: // value for 'items'
 *   },
 * });
 */
export function useGetEmployeesPositionsTableQuery(baseOptions: Apollo.QueryHookOptions<GetEmployeesPositionsTableQuery, GetEmployeesPositionsTableQueryVariables> & ({ variables: GetEmployeesPositionsTableQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeesPositionsTableQuery, GetEmployeesPositionsTableQueryVariables>(GetEmployeesPositionsTableDocument, options);
      }
export function useGetEmployeesPositionsTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeesPositionsTableQuery, GetEmployeesPositionsTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeesPositionsTableQuery, GetEmployeesPositionsTableQueryVariables>(GetEmployeesPositionsTableDocument, options);
        }
export function useGetEmployeesPositionsTableSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEmployeesPositionsTableQuery, GetEmployeesPositionsTableQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEmployeesPositionsTableQuery, GetEmployeesPositionsTableQueryVariables>(GetEmployeesPositionsTableDocument, options);
        }
export type GetEmployeesPositionsTableQueryHookResult = ReturnType<typeof useGetEmployeesPositionsTableQuery>;
export type GetEmployeesPositionsTableLazyQueryHookResult = ReturnType<typeof useGetEmployeesPositionsTableLazyQuery>;
export type GetEmployeesPositionsTableSuspenseQueryHookResult = ReturnType<typeof useGetEmployeesPositionsTableSuspenseQuery>;
export type GetEmployeesPositionsTableQueryResult = Apollo.QueryResult<GetEmployeesPositionsTableQuery, GetEmployeesPositionsTableQueryVariables>;
export const DeleteEmployeePositionDocument = gql`
    mutation DeleteEmployeePosition($id: Int!, $newPositionId: Int!) {
  deleteEmployeePosition(id: $id, newPositionId: $newPositionId)
}
    `;
export type DeleteEmployeePositionMutationFn = Apollo.MutationFunction<DeleteEmployeePositionMutation, DeleteEmployeePositionMutationVariables>;

/**
 * __useDeleteEmployeePositionMutation__
 *
 * To run a mutation, you first call `useDeleteEmployeePositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmployeePositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmployeePositionMutation, { data, loading, error }] = useDeleteEmployeePositionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      newPositionId: // value for 'newPositionId'
 *   },
 * });
 */
export function useDeleteEmployeePositionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEmployeePositionMutation, DeleteEmployeePositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEmployeePositionMutation, DeleteEmployeePositionMutationVariables>(DeleteEmployeePositionDocument, options);
      }
export type DeleteEmployeePositionMutationHookResult = ReturnType<typeof useDeleteEmployeePositionMutation>;
export type DeleteEmployeePositionMutationResult = Apollo.MutationResult<DeleteEmployeePositionMutation>;
export type DeleteEmployeePositionMutationOptions = Apollo.BaseMutationOptions<DeleteEmployeePositionMutation, DeleteEmployeePositionMutationVariables>;
export const DeleteEmployeePositionByIdDocument = gql`
    mutation DeleteEmployeePositionById($id: Int!) {
  deleteEmployeePosition(id: $id)
}
    `;
export type DeleteEmployeePositionByIdMutationFn = Apollo.MutationFunction<DeleteEmployeePositionByIdMutation, DeleteEmployeePositionByIdMutationVariables>;

/**
 * __useDeleteEmployeePositionByIdMutation__
 *
 * To run a mutation, you first call `useDeleteEmployeePositionByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmployeePositionByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmployeePositionByIdMutation, { data, loading, error }] = useDeleteEmployeePositionByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEmployeePositionByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEmployeePositionByIdMutation, DeleteEmployeePositionByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEmployeePositionByIdMutation, DeleteEmployeePositionByIdMutationVariables>(DeleteEmployeePositionByIdDocument, options);
      }
export type DeleteEmployeePositionByIdMutationHookResult = ReturnType<typeof useDeleteEmployeePositionByIdMutation>;
export type DeleteEmployeePositionByIdMutationResult = Apollo.MutationResult<DeleteEmployeePositionByIdMutation>;
export type DeleteEmployeePositionByIdMutationOptions = Apollo.BaseMutationOptions<DeleteEmployeePositionByIdMutation, DeleteEmployeePositionByIdMutationVariables>;
export const GetUserRoleDocument = gql`
    query GetUserRole {
  role {
    id
    name
    isOwner
  }
  user {
    id
  }
}
    `;

/**
 * __useGetUserRoleQuery__
 *
 * To run a query within a React component, call `useGetUserRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRoleQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserRoleQuery(baseOptions?: Apollo.QueryHookOptions<GetUserRoleQuery, GetUserRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserRoleQuery, GetUserRoleQueryVariables>(GetUserRoleDocument, options);
      }
export function useGetUserRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserRoleQuery, GetUserRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserRoleQuery, GetUserRoleQueryVariables>(GetUserRoleDocument, options);
        }
export function useGetUserRoleSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUserRoleQuery, GetUserRoleQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserRoleQuery, GetUserRoleQueryVariables>(GetUserRoleDocument, options);
        }
export type GetUserRoleQueryHookResult = ReturnType<typeof useGetUserRoleQuery>;
export type GetUserRoleLazyQueryHookResult = ReturnType<typeof useGetUserRoleLazyQuery>;
export type GetUserRoleSuspenseQueryHookResult = ReturnType<typeof useGetUserRoleSuspenseQuery>;
export type GetUserRoleQueryResult = Apollo.QueryResult<GetUserRoleQuery, GetUserRoleQueryVariables>;
export type GetEmployeesQueryVariables = Types.Exact<{
  page: Types.Scalars['Int']['input'];
  itemsPerPage: Types.Scalars['Int']['input'];
  name?: Types.InputMaybe<Types.Scalars['String']['input']>;
  positionId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  statusId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetEmployeesQuery = { __typename?: 'Query', role: Array<{ __typename?: 'RoleProxy', id: number, name: string, isOwner: boolean }>, user?: { __typename?: 'UserProxy', id: number, email?: string | null, company?: { __typename?: 'CompanyProxy', paidFeatures?: Array<{ __typename?: 'PaidFeatureProxy', id: number, name: string, dateOfEnd?: string | null }> | null, employees?: { __typename?: 'EmployeePaginated', totalCount?: number | null, currentPage?: number | null, pageCount?: string | null, employees?: Array<{ __typename?: 'EmployeeProxy', id: number, name?: string | null, surname?: string | null, email: string, phone: string, tariffBlocking: boolean, manualBlocking: boolean, specialization?: string | null, image?: string | null, contract?: string | null, status?: { __typename?: 'EmployeeStatusProxy', id: number, name?: string | null } | null, position?: { __typename?: 'EmployeePositionProxy', id: number, name: string } | null, role?: { __typename?: 'RoleProxy', id: number, name: string } | null, employeeServiceRelations?: Array<{ __typename?: 'EmployeeServiceRelationProxy', id: number }> | null, workSchedules?: Array<{ __typename?: 'WorkScheduleProxy', id: number, startDate: string, endDate: string }> | null, userBranchRelations?: Array<{ __typename?: 'UserBranchRelationProxy', branch: { __typename?: 'BranchProxy', id: number } }> | null, reviews?: Array<{ __typename?: 'ReviewProxy', id: number, name: string, rating: number }> | null, recordSetting?: { __typename?: 'RecordSettingProxy', id: number, isDefaultEmployee: boolean, isOnlineSignUpAvailable: boolean, isReviewsAvailable: boolean } | null, qualificationConfirmations?: Array<{ __typename?: 'QualificationConfirmationProxy', id: number, image?: string | null }> | null, dismissalEmployee?: { __typename?: 'DismissalEmployeeProxy', id: number } | null }> | null } | null } | null } | null };

export type GetEmployeesTariffBlockingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetEmployeesTariffBlockingsQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', company?: { __typename?: 'CompanyProxy', employees?: { __typename?: 'EmployeePaginated', employees?: Array<{ __typename?: 'EmployeeProxy', id: number, tariffBlocking: boolean, position?: { __typename?: 'EmployeePositionProxy', id: number, name: string } | null }> | null } | null } | null } | null };

export type GetEmployeesPositionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetEmployeesPositionsQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', company?: { __typename?: 'CompanyProxy', employeePositions?: { __typename?: 'EmployeePositionPaginated', employeePositions?: Array<{ __typename?: 'EmployeePositionProxy', id: number, name: string }> | null } | null } | null } | null };

export type GetStatusQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetStatusQuery = { __typename?: 'Query', employeeStatus: Array<{ __typename?: 'EmployeeStatusProxy', id: number, name?: string | null }> };

export type GetCompanyIdQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCompanyIdQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', id: number, company?: { __typename?: 'CompanyProxy', id: number, name: string } | null } | null };

export type ChangeTariffBlockingMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  name: Types.Scalars['String']['input'];
  surname: Types.Scalars['String']['input'];
  email: Types.Scalars['String']['input'];
  phone: Types.Scalars['String']['input'];
  specialization: Types.Scalars['String']['input'];
  employeePosition: Types.Scalars['Int']['input'];
  role: Types.Scalars['Int']['input'];
  tariffBlocking: Types.Scalars['Boolean']['input'];
  manualBlocking: Types.Scalars['Boolean']['input'];
  status: Types.Scalars['Int']['input'];
  isUpdateAccess?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type ChangeTariffBlockingMutation = { __typename?: 'Mutation', updateEmployee: { __typename?: 'EmployeeProxy', id: number, name?: string | null, tariffBlocking: boolean, manualBlocking: boolean } };

export type UpdateEmployeeRecordSettingsMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  isOnlineSignUpAvailable: Types.Scalars['Boolean']['input'];
  isDefaultEmployee: Types.Scalars['Boolean']['input'];
  isReviewsAvailable: Types.Scalars['Boolean']['input'];
}>;


export type UpdateEmployeeRecordSettingsMutation = { __typename?: 'Mutation', updateEmployeeRecordSettings: { __typename?: 'RecordSettingProxy', id: number, isOnlineSignUpAvailable: boolean, isDefaultEmployee: boolean, isReviewsAvailable: boolean } };

export type DismissEmployeeMutationVariables = Types.Exact<{
  employeeId: Types.Scalars['Int']['input'];
  endOfCooperation: Types.Scalars['String']['input'];
  reason: Types.Scalars['Int']['input'];
  comment: Types.Scalars['String']['input'];
}>;


export type DismissEmployeeMutation = { __typename?: 'Mutation', dismissEmployee: { __typename?: 'DismissalEmployeeProxy', id: number } };

export type DeleteDismissEmployeeMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type DeleteDismissEmployeeMutation = { __typename?: 'Mutation', deleteDismissEmployee: boolean };

export type GetDismissalReasonQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetDismissalReasonQuery = { __typename?: 'Query', dismissalReason: Array<{ __typename?: 'EmployeeDismissalReasonProxy', id: number, name: string }> };

export type GetEmployeesPositionsTableQueryVariables = Types.Exact<{
  page: Types.Scalars['Int']['input'];
  items: Types.Scalars['Int']['input'];
}>;


export type GetEmployeesPositionsTableQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', company?: { __typename?: 'CompanyProxy', employeePositions?: { __typename?: 'EmployeePositionPaginated', totalCount?: number | null, currentPage?: number | null, itemsPerPage?: number | null, pageCount?: string | null, employeePositions?: Array<{ __typename?: 'EmployeePositionProxy', id: number, name: string, description?: string | null, employees?: Array<{ __typename?: 'EmployeeProxy', id: number, specialization?: string | null }> | null }> | null } | null } | null } | null };

export type DeleteEmployeePositionMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  newPositionId: Types.Scalars['Int']['input'];
}>;


export type DeleteEmployeePositionMutation = { __typename?: 'Mutation', deleteEmployeePosition: boolean };

export type DeleteEmployeePositionByIdMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type DeleteEmployeePositionByIdMutation = { __typename?: 'Mutation', deleteEmployeePosition: boolean };

export type GetUserRoleQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetUserRoleQuery = { __typename?: 'Query', role: Array<{ __typename?: 'RoleProxy', id: number, name: string, isOwner: boolean }>, user?: { __typename?: 'UserProxy', id: number } | null };

import React from 'react';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {currentCompanyIdVar} from '@me-team/host/src/apollo/globalVar/state';
import {InfoBanner, Spinner, TableHeader} from '@services/ui-components';
import {Row} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import UpdateRecoveryProductForm from './UpdateRecoveryProductForm';

const UpdateProduct: React.FC = () => {
  const { t } = useTranslation();
  const {id} = useParams();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);

  if (!currentCompanyId) return <Spinner/>;
  return (
    <div className='mb-3'>
      <InfoBanner>
        <p className='fs-7 mb-0'>{t('')}</p>
      </InfoBanner>
      <TableHeader
        title={t('Edit Product')}
        paths={[
          {path: `/warehouse/product/${currentCompanyId}/${id}/edit-product`, label: t('Warehouse' +
              ' accounting')},
          {path: `/warehouse/product/${currentCompanyId}`, label: t('Products')},
          {path: `/warehouse/product/${currentCompanyId}/${id}/edit-product`, label: t('Edit' +
              ' Product')},
        ]}
        colSize={12}
      />
      <Row>
        <UpdateRecoveryProductForm />
      </Row>
    </div>
  );
};

export default UpdateProduct;
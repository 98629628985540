import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { OverlayTrigger, Tooltip, Popover, Row, Button, Col, Container } from 'react-bootstrap';
import i18next from "i18next";
import { ErrorModalComponent } from '@me-pos/error-modal';
import { Pagination, } from '@services/ui-components';
import { useChangeTariffBlockingMutation, useGetEmployeesQuery, useGetEmployeesTariffBlockingsQuery, useGetUserRoleQuery, } from '@me-team/host/src/modules/employeeList/graphql/employeeList.hooks';
import { compareDates } from '../utils/dateUtils';
import BookingSettingsModal from '../modals/BookingSettingsModal/BookingSettingsModal';
import DeleteEmployeeModal from '../modals/DeleteEmployeeModal/DeleteEmployeeModal';
import RatingStars from "@services/ui-components/src/RatingStars/RatingStars";
import Spinner from '@services/ui-components/src/Spinner/Spinner';
import EmployeeCell from "@services/ui-components/src/EmployeeCell/EmployeeCell";
import { currentBranchIdVar, currentCompanyIdVar } from '@me-team/host/src/apollo/globalVar/state';
import ErrorService from "../../../services/ErrorService";


interface Employee {
    id: number;
    name: string;
    surname: string;
    email: string;
    phone: string;
    employeeServiceRelations: {
        id: number;
    }[];
    status: {
        id: number;
        name: string;
    };
    position: {
        id: number;
        name: string;
    };
    tariffBlocking: boolean;
    manualBlocking: boolean;
    specialization: string;
    userBranchRelations: {
        branch: {
            id: number;
        };
    }[];
    workSchedules: {
        id: number;
        endDate: string;
        startDate: string;
    }[];
    reviews: any[];
    role: {
        id: number;
        name: string;
    };

}

interface Filter {
    name: string;
    positionId: string | number;
    statusId: string | number;

}

const hoverStyle = {
    backgroundColor: 'inherit',
    color: 'inherit',
    border: 'none',
};
const EmployeesTable: React.FC<{ filter: Filter, currentPage: number, setCurrentPage: (page: number) => void, accessService: boolean, position: string | number, }> = ({ filter, currentPage, setCurrentPage, accessService }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [showBookingSettingsModa, setShowBookingSettingsModa] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(null);
    const handleToggleBookingSettingsModal = () => setShowBookingSettingsModa(prevState => !prevState);

    const basePath = process.env.REACT_APP_POS_URL;
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);
    const currentBranchId = useReactiveVar(currentBranchIdVar);

    const { data, loading, error, refetch } = useGetEmployeesQuery({
        variables: { page: currentPage, itemsPerPage: itemsPerPage, name: filter.name, positionId: +filter.positionId, statusId: +filter.statusId },
        onError: (error) => {
            setIsErrorModalOpen(ErrorService.errorHandling(error))
        },
        context: {
            errorType: 'global',
        },

    });

    const { data: tariffBlocking, loading: loadingTariffBlocking, error: errorTariffBlocking, refetch: refetchTariffBlocking } = useGetEmployeesTariffBlockingsQuery({
        onError: (error) => {
            setIsErrorModalOpen(ErrorService.errorHandling(error))
        }
    })
    const { data: role, loading: loadingRole } = useGetUserRoleQuery({
        onError: (error) => {
            setIsErrorModalOpen(ErrorService.errorHandling(error))
        }
    })

    const [changeAccess] = useChangeTariffBlockingMutation()

    const employees = data?.user.company.employees.employees;
    const tariffBlockingCount = useMemo(() => {
        return tariffBlocking?.user.company.employees.employees.filter(employee => !employee.tariffBlocking).length || 0;
    }, [tariffBlocking]);
    const email = data?.user.email
    const pageCount = data?.user.company.employees.pageCount
    const totalItems = data?.user.company.employees.totalCount || 0;
    const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
    const lastItemIndex = Math.min(currentPage * itemsPerPage, totalItems);
    const userRole = role?.user?.role?.id
    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };
    const deleteEmployee = (employee: Employee) => {
        setSelectedEmployee(employee);
        setDeleteModalShow(true);
    };
    const bookingSettings = (employee: Employee) => {
        setSelectedEmployee(employee);
        setShowBookingSettingsModa(true);
    };
    const getStatusClass = (statusId: number) => {
        switch (statusId) {
            case 2:
                return "bg-green";
            case 4:
                return "bg-danger";
            case 3:
                return "bg-grey";
            case 1:
                return "bg-orange";
            default:
                return "";
        }
    }

    const handleAccess = async (values: Employee) => {
        const variables = {
            id: values.id,
            name: values.name,
            surname: values.surname,
            email: values.email,
            phone: values.phone,
            specialization: values.specialization,
            employeePosition: values.position.id,
            role: values.role.id,
            status: values.status.id,
            tariffBlocking: !values.tariffBlocking,
            manualBlocking: !values.tariffBlocking,
            isUpdateAccess: true
        }
        try {
            const response = await changeAccess({ variables })
            refetchTariffBlocking()
            refetch()
        } catch (error) {
            console.error('Error creating Employee:', error)
        }
    }

    if (loading || loadingTariffBlocking || loadingRole) return <Spinner />

    return (
        <>
            {
                !accessService &&
                <div className="bg-danger-light text-dark border-start border-4 border-danger rounded fs-7 " style={{ margin: '36px 0 36px 0', padding: '12px 24px ' }}>
                    {t('With your tariff, only inclusion of 7 employees is available (5 masters, 1 administrator, and 1 owner). You can enable/disable access for any 7 from the list of created employees. If you need more, upgrade your tariff.')} <Link to='/'>Premium</Link> . {t('Disabled employees are only available on this page and will not be displayed in the appointments log, work schedule, online booking, and other me-pos pages.')}
                </div >
            }
            {employees?.length === 0 ?
                <Container className="mt-5">
                    <Row className="mt-5">
                        <Col xs={12}>
                            <h4 className="head-personal-data text-center fw-normal pt-5 mt-5">
                                {t("NoDataFoundForTheGivenValues")}
                            </h4>
                        </Col>
                    </Row>
                </Container>
                :
                <div className="table-responsive scrollbar ">
                    <table className="table">
                        <thead >
                            <tr className='fs-7'>
                                {
                                    !accessService && userRole !== 5 &&
                                    <th>{t('Access')}  {' '}
                                        <OverlayTrigger
                                            key={"bottom"}
                                            placement={"bottom"}
                                            overlay={
                                                <Popover id="popover-contained" >
                                                    <Popover.Body >
                                                        <Row >
                                                            <p className='m-0'> On - {t('On - the employee is enabled, they are displayed in the work schedule, and available for online booking.')}</p>

                                                        </Row>
                                                        <Row className="row">
                                                            <p className='m-0'> <span className=" text-secondary"> Off</span> {t('- the employee is disabled, they are only displayed on this page.')}</p>

                                                        </Row>
                                                    </Popover.Body>
                                                </Popover>
                                            }
                                        >
                                            <i className="bi bi-info-circle"> </i>
                                        </OverlayTrigger>   </th>
                                }
                                <th>
                                    {t('Status')} {' '}
                                    <OverlayTrigger
                                        key={"bottom"}
                                        placement={"bottom"}
                                        overlay={
                                            <Popover id="popover-contained" className=" popover-max-width">
                                                <Popover.Body >
                                                    <p className='m-0'><span className=" text-green"><i className="bi bi-square-fill"></i> {t('Active')} </span>{t('- has a schedule, free time, and available for online booking.')}</p>
                                                    <p className='m-0'>
                                                        <span className=" text-orange"><i className="bi bi-square-fill"></i> {t('Busy')} </span>{t('- the employee has no available slots for booking throughout their scheduled work days.')}
                                                    </p>
                                                    <p className='m-0'><span className=" text-grey"><i className="bi bi-square-fill"></i> {t('Hidden')} </span>{t('- hidden from the online booking form.')} </p>
                                                    <p className='m-0' ><span className=" text-danger"><i className="bi bi-square-fill"></i> {t('Fired')}  </span>{t('- the employee has been fired.')}</p>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <i className="bi bi-info-circle"> </i>
                                    </OverlayTrigger>
                                </th>
                                <th>{t('Employee')}</th>
                                <th>{t('Provides Services')}</th>
                                <th>{t('Branches')}</th>
                                <th>{t('Schedule')} {' '}
                                    <OverlayTrigger
                                        key={"bottom"}
                                        placement={"bottom"}
                                        overlay={
                                            <Popover >
                                                <Popover.Body  >
                                                    <Row>
                                                        <p className="m-0 mb-1" ><span className=" text-green"><i className="bi bi-square"></i> {t('Installed')} </span> {t('- if the schedule is set.')} </p>
                                                    </Row>

                                                    <p className="m-0">
                                                        <span className=" text-orange"><i className="bi bi-square"></i> {t('Missed')}</span> {t('- possible in cases:')}

                                                        <ul className="m-0">
                                                            <li>{t('the schedule for the employee has not been installed;')}</li>
                                                            <li>{t('the schedule has expired;')}</li>

                                                        </ul>
                                                    </p>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <i className="bi bi-info-circle"> </i>
                                    </OverlayTrigger>
                                </th>
                                <th>{t('Rating')}</th>
                                <th></th>
                            </tr>
                        </thead >

                        <tbody>

                            {employees?.map((i: Employee) => (
                                <tr key={i?.id} >
                                    {
                                        !accessService && userRole !== 5 &&
                                        <td className='align-middle'>
                                            <div className="form-check form-switch">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    role="switch"
                                                    id={`flexSwitchCheckCheckedDisabled-${i.id}`}
                                                    checked={!i.tariffBlocking}
                                                    onChange={() => handleAccess(i)}
                                                    disabled={
                                                        i.tariffBlocking && tariffBlockingCount >= 7 ? true : false
                                                    }
                                                />
                                            </div>
                                        </td>
                                    }
                                    <td className='align-middle'>
                                        <strong className={`btn ${getStatusClass(i?.status?.id)} text-white w-100 py-1 fw-bold no-hover `} style={{ cursor: 'default' }} >
                                            {i?.status.name}
                                        </strong>
                                    </td>
                                    <td className='align-middle'>
                                        <EmployeeCell i={i} />
                                    </td>
                                    <td className='align-middle'>
                                        <Link
                                            to={`${basePath}/personnel/employee/${currentCompanyId}/list/${i.id}/services`}
                                            className='text-decoration-none'
                                        >
                                            {i?.employeeServiceRelations.length}
                                        </Link>

                                    </td>
                                    <td className='align-middle'>{i?.userBranchRelations.length}</td>
                                    <td className='align-middle'>{compareDates(i?.workSchedules, t('Missed'), t('Installed'))}</td>
                                    <td className='align-middle '>
                                        <div className='d-flex flex-row'>
                                            <RatingStars rating={i?.reviews} />
                                        </div>
                                    </td>
                                    <td className='text-end ' style={{ paddingRight: '0' }}>
                                        {role?.user?.role?.id === 5 && email === i?.email ? (
                                            <div className='d-flex justify-content-end '>
                                                <OverlayTrigger overlay={<Tooltip>{t('Edit')}</Tooltip>}>
                                                    <Button
                                                        variant="outline-secondary"
                                                        onClick={() => navigate('/employees-list/edit-employee/' + i.id)}
                                                        className="text-dark m-1 p-0"
                                                        style={{ height: '39px', width: '39px' }}
                                                    >
                                                        <i className="bi bi-pencil"></i>
                                                    </Button>
                                                </OverlayTrigger>
                                                <OverlayTrigger overlay={<Tooltip>{t('Employee services')}</Tooltip>}>
                                                    <Button
                                                        variant="outline-secondary"
                                                        // onClick={() => navigate(`${basePath}/personnel/employee/${currentCompanyId}/list/${i.id}/services`)}
                                                        className="text-dark m-1 p-0"
                                                        style={{ height: '39px', width: '39px' }}
                                                    >
                                                        <Link
                                                            to={`${basePath}/personnel/employee/${currentCompanyId}/list/${i.id}/services`}
                                                            className='text-decoration-none'
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                width: '100%',
                                                                height: '100%',
                                                                textAlign: 'center',
                                                                color: '#1F3354'
                                                            }}
                                                        >
                                                            <i className="bi bi-bookmark"></i>
                                                        </Link>

                                                    </Button>
                                                </OverlayTrigger>
                                                <OverlayTrigger overlay={<Tooltip>{t('Schedule')}</Tooltip>}>
                                                    <Button
                                                        variant="outline-secondary"
                                                        // onClick={() => navigate(`${basePath}/personnel/schedule/${currentCompanyId}/${currentBranchId}/employee?filter_form%5Bemployee%5D=${i.id}`)}
                                                        className="text-dark m-1 p-0"
                                                        style={{
                                                            height: '39px', width: '39px', display: 'inline-flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            position: 'relative'
                                                        }}
                                                        disabled={i.status.name === "Fired"}
                                                    >
                                                        <Link
                                                            to={`${basePath}/personnel/schedule/${currentCompanyId}/${currentBranchId}/employee?filter_form%5Bemployee%5D=${i.id}`}
                                                            className='text-decoration-none text-dark'
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                width: '100%',
                                                                height: '100%',
                                                                textAlign: 'center',
                                                                color: '#1F3354'
                                                            }}
                                                        >
                                                            <i className="bi bi-calendar4-week text-dark"></i>
                                                        </Link>

                                                    </Button>
                                                </OverlayTrigger>
                                                <OverlayTrigger overlay={<Tooltip>{t('Booking Settings')}</Tooltip>}>
                                                    <Button
                                                        variant="outline-secondary"
                                                        className="text-dark m-1 p-0"
                                                        onClick={() => bookingSettings(i)}
                                                        disabled={i.status.name === "Fired"}
                                                        style={{
                                                            height: '39px', width: '39px', display: 'inline-flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            position: 'relative'
                                                        }}
                                                    >
                                                        <i className="bi bi-gear"></i>
                                                    </Button>
                                                </OverlayTrigger>
                                                <OverlayTrigger overlay={<Tooltip>{t('Fire an employee')}</Tooltip>}>
                                                    <Button
                                                        variant="outline-secondary"
                                                        className="m-1 p-0"
                                                        onClick={() => deleteEmployee(i)}
                                                        style={{ height: '39px', width: '39px' }}
                                                    >
                                                        <i className="bi bi-person-dash text-dark" />
                                                    </Button>
                                                </OverlayTrigger>
                                            </div>
                                        ) : (role?.user?.role?.id === 1 || role?.user?.role?.id === 3) ? (
                                            <>
                                                <OverlayTrigger overlay={<Tooltip>{t('Edit')}</Tooltip>}>
                                                    <Button
                                                        variant='outline-secondary'
                                                        onClick={() => navigate('/employees-list/edit-employee/' + i.id)}
                                                        className=" text-dark m-1 p-0"
                                                        style={{ height: '39px', width: '39px' }}
                                                    >
                                                        <i className="bi bi-pencil"></i>
                                                    </Button>
                                                </OverlayTrigger>
                                                <OverlayTrigger overlay={<Tooltip>{t('Employee services')}</Tooltip>}>
                                                    <Button
                                                        variant='outline-secondary'
                                                        // onClick={() => navigate(`${basePath}/personnel/employee/${currentCompanyId}/list/${i.id}/services`)}
                                                        className="text-dark m-1 p-0"
                                                        style={{
                                                            height: '39px', width: '39px', display: 'inline-flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            position: 'relative'
                                                        }}
                                                        disabled={i.tariffBlocking}
                                                    >

                                                        <Link
                                                            to={`${basePath}/personnel/employee/${currentCompanyId}/list/${i.id}/services`}
                                                            className='text-decoration-none'
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                width: '100%',
                                                                height: '100%',
                                                                textAlign: 'center',
                                                                color: '#1F3354'
                                                            }}
                                                        >
                                                            <i className="bi bi-bookmark"></i>
                                                        </Link>
                                                    </Button>
                                                </OverlayTrigger>
                                                <OverlayTrigger overlay={<Tooltip>{t('Schedule')}</Tooltip>}>
                                                    <Button
                                                        variant='outline-secondary'
                                                        // onClick={() => navigate(`${basePath}/personnel/schedule/${currentCompanyId}/${currentBranchId}/employee?filter_form%5Bemployee%5D=${i.id}`)}
                                                        className="text-dark m-1 p-0"
                                                        style={{
                                                            height: '39px', width: '39px', display: 'inline-flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            position: 'relative'
                                                        }}
                                                        disabled={i.tariffBlocking}
                                                    >
                                                        <Link
                                                            to={`${basePath}/personnel/schedule/${currentCompanyId}/${currentBranchId}/employee?filter_form%5Bemployee%5D=${i.id}`}
                                                            className='text-decoration-none'
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                width: '100%',
                                                                height: '100%',
                                                                textAlign: 'center',
                                                                color: '#1F3354'
                                                            }}
                                                        >
                                                            <i className="bi bi-calendar4-week"></i>
                                                        </Link>

                                                    </Button>
                                                </OverlayTrigger>

                                                <OverlayTrigger overlay={<Tooltip>{t('Booking Settings')}</Tooltip>}>
                                                    <Button
                                                        variant='outline-secondary'
                                                        className="outline-secondary text-dark  m-1 p-0"
                                                        onClick={() => bookingSettings(i)}
                                                        disabled={i.status.name == "Fired" || i.tariffBlocking}
                                                        style={{ height: '39px', width: '39px' }}
                                                    >
                                                        <i className="bi bi-gear"></i>
                                                    </Button>
                                                </OverlayTrigger>
                                                <OverlayTrigger overlay={<Tooltip>{t('Fire an employee')}</Tooltip>}>
                                                    <Button
                                                        variant='outline-secondary'
                                                        className="outline-secondary m-1 p-0"
                                                        onClick={() => deleteEmployee(i)}
                                                        style={{ height: '39px', width: '39px' }}
                                                    >
                                                        <i className="bi bi-person-dash text-dark" />
                                                    </Button>
                                                </OverlayTrigger>
                                            </>
                                        ) : (
                                            <td></td>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody >
                    </table >
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <Pagination
                            t={t}
                            pages={Number(pageCount)}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                            firstItemIndex={firstItemIndex}
                            lastItemIndex={lastItemIndex}
                            totalItems={totalItems}
                        />
                    </div>
                </div >
            }
            <DeleteEmployeeModal
                show={deleteModalShow}
                onHide={() => setDeleteModalShow(false)}
                selectedEmployee={selectedEmployee}
                refetch={refetch}
            />
            <BookingSettingsModal
                show={showBookingSettingsModa}
                toggleModal={handleToggleBookingSettingsModal}
                selectedEmployee={selectedEmployee}
                refetch={refetch}
            />
            {isErrorModalOpen &&
                <ErrorModalComponent
                    i18n={i18next}
                    onClose={() => {
                        setIsErrorModalOpen(null)
                    }}
                    isOpen={!!isErrorModalOpen}
                    currentError={isErrorModalOpen}
                />
            }
        </>
    );
};

export default EmployeesTable;
